@import "themes/default";
.footer{
  background-color: @nav-background;
  color: @foot-color;
  padding: 15px 0;
  .ant-typography{
    color: #ffffff;
  }
  .footer-desc{
    margin-top: 10px;
    font-size: 14px;
  }
  .footer-content{
     margin-right: 30px;
     ul,li{
       margin: 0;
       padding: 0 0 5px;
       list-style: none;
     }
     a{
        color: @foot-link-color;
       &:hover{
         color: @foot-link-color-hover
       }
     }
  }
  .ant-col:last-child{
    .footer-content{
      margin-right: 0;
    }
  }
  .footer-desc{
    p{
      margin-bottom: 0;
      i{
        margin-right: 3px;
        &.icon-phone{
          margin-right: 6px;
        }
      }
    }
    padding-bottom: 15px;
  }
}
.pd-footer{
  background-color: #096dd9;
  font-size: 14px;
  line-height: 26px;
  color:@white-color;
  text-align: center;
  p{
    margin: 0;
  }
  span{
    margin: 0 15px;
  }
}
