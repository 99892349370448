@charset "UTF-8";
@import "themes/default";

/*首页走马灯*/

.home-box {
  background-size: cover;
  position: relative;

  .ant-carousel {
    height: 100%;
    width: 100%;
  }

  .ant-carousel>div {
    height: 100%;
    width: 100%;
  }

  .slick-slider>div {
    height: 100%;
    width: 100%;
  }

  .slick-list>div {
    height: 100%;
    width: 100%;
  }

  .slick-slide {
    height: 400px !important;
    width: 100%;

    &>div {
      height: 100%;
      width: 100%;

      .home-bkg {
        height: 100%;
        width: 100%;

        .home-img-box {
          float: left;
          width: 100%;
          background-position: center;
          display: table;
          height: 100%;
          background-size: cover !important;
        }

        .home-inner {
          height: 100%;

          color: #fff;
          padding-top: 40px;

          vertical-align: middle;
          background-color: rgba(0, 0, 0, 0.4);

          .home-content {
            max-width: 1100px;
            margin-left: auto;
            margin-right: auto;

            .home-title {
              padding: 0;
              font-size: 42px;
              line-height: 1.4em;
              text-align: left;
              color: #fff;
            }

            .home-sub-text {
              padding: 5px;
              font-weight: 300;
              line-height: 1.8em;
              color: rgba(255, 255, 255, 0.8);
              text-align: left;
              font-size: 32px;
              color: #3aa4e8;
              margin-top: 30px;
            }
          }
        }
      }
    }
  }

  .home-tab {
    height: 553px;

    //.ant-tabs-nav-scroll {
    //  text-align: center;
    //}
  }
}

.home-sider-img-box {
  position: relative;
  height: 217px;
  margin: 15px;
  overflow: hidden;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  &:hover {
    .home-sider-tit-content {
      bottom: 0;
    }

    .home-sider-img-tit {
      transform: translateY(100%);
    }
  }
}

.home-sider-img-tit {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 44px;
  line-height: 44px;
  padding: 0 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  transition: all 0.2s;
}

.home-sider-tit-content {
  position: absolute;
  bottom: -100%;
  height: 100%;
  width: 100%;
  padding: 5%;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.7);
  transition: all 0.5s;

  .home-sider-tit1 {
    font-size: 18px;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .home-sider-tit-p {
    font-size: 13px;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

/*首页走马灯-end*/

/*首页*/
.home-navigation {
  position: absolute;
  height: 100%;
  width: 10%;
  // top: 10%;
  z-index: 9;
  overflow: hidden;
}

.home-notice {
  .ant-tag {
    width: 71px;
    height: 32px;
    font-size: 14px;
    border-radius: 4px;
    line-height: 32px;
    text-align: center;
    color: #666666;
    background: rgba(238, 238, 238, 1) !important;
  }
}

.home-content {
  .ant-btn {

    // width: 220px;
    &:hover {
      color: #fff;
      background-color: @primary-color;
      border-color: @primary-color;
    }
  }
}

.home-text-content {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  width: 100%;

  t-card {
    border: 1px solid rgba(229, 229, 229, 1);
  }

  .index-price-img {
    .images-auto {
      border-radius: 0;
    }
  }
}

.index-activity-card {
  border: 1px solid rgba(229, 229, 229, 1);

  &:hover {
    .title {
      color: @primary-color  !important;
    }
  }

  &.ant-card-small>.ant-card-body {
    padding: 21px 18px;
  }
}

.home-arrow {
  display: block;
  height: 100%;
  width: 90%;
  transition: all 0.2s ease-out;
}

.home-img-prev {
  left: 0;

  &:hover {
    .home-arrow {
      opacity: 0.5;
      margin: 0px 0px 0px 20%;
      cursor: pointer;
    }
  }

  .home-arrow {
    opacity: 0;
    margin: 0px 0px 0px -20%;
    font-size: 50px;
    color: #fff;
    display: flex;
    align-items: center;
  }
}

.home-img-next {
  right: 0;

  &:hover {
    .home-arrow {
      opacity: 0.5;
      margin: 0 0 0 20%;
      cursor: pointer;
    }
  }

  .home-arrow {
    opacity: 0;
    margin: 0 0 0 70%;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 50px;
    justify-content: center;
  }
}

.index-container {
  margin-top: 25px;
  margin-bottom: 25px;
  position: relative;
  overflow: hidden;

  .home-item {
    background-color: #fff;
    box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    margin: 15px;

    .home-activity-set {
      position: relative;
      height: 217px;
      margin-bottom: 2rem;
      overflow: hidden;
      cursor: pointer;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }
}

.home-features-title {
  font-size: 16px;
  color: #262626;
  margin: 8px 0 -10px;
  font-weight: bold;
}

.home-new {
  .newtime {
    font-size: 12px;
    position: relative;
    margin: 0;
    padding-left: 15px;

    span {
      background: #cccccc;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      position: absolute;
      left: -4px;
      top: 5px;
    }

    label {
      font-weight: normal;
      margin: 0 2% 0 2%;
      font-size: 14px;
      display: inline-block;
    }
  }

  .box-bor {
    border-left: 2px solid #cccccc;
    padding-left: 15px;
    margin-top: -6px;
    padding-top: 15px;

    .home-news-title {
      color: #333333;
      font-size: 18px;
    }

    .home-news-text {
      color: #9e9e9e;
      font-size: 14px;
    }
  }
}

.home-notice {
  position: relative;
  overflow: hidden;
  height: 64px;
  line-height: 64px;
  background-color: #f9f9f9;

  .home-notice-swiper {
    height: 64px;
    width: 100%;

    .swiper-slide {
      text-align: left;
      line-height: 64px;
      font-size: 16px;
    }
  }
}

.home-body {
  position: relative;
  min-height: calc(~'100vh - 250px');
  background: url("../images/pudong/body-bg.jpg") no-repeat center;
  background-size: cover;
}

.home-content {
  position: relative;
  overflow: hidden;
  margin-top: 48px;
}

.homt-title {
  position: relative;
  overflow: hidden;
  text-align: center;
  font-size: 36px;
  color: #000000;
  line-height: 34px;
  padding: 40px 0 40px;
  margin-bottom: 40px;

  &::after {
    position: absolute;
    content: "";
    width: 42px;
    border-radius: 2px;
    height: 4px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #2289cb;
    bottom: 16px;
  }
}

.home-service-list {
  position: relative;
}

.home-service-row {
  position: relative;
  display: flex;
  align-items: center;
  height: 122px;
  border-radius: 4px;
  color: #333;
  margin-bottom: 30px;
  border: 1px solid #f0f0f0;
  transition: all 0.3s;

  // cursor: pointer;
  >a {
    padding: 20px 25px;
    color: #333;
    display: block;
    width: 100%;
    height: 100%;

    &:hover {
      color: #fff;
    }
  }

  >div {
    width: 100%;
  }

  .size-20 {
    line-height: 1;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: @primary-color;
    color: #fff;
  }

  .home-service-a {
    cursor: pointer !important;
    background-color: #2eaaf9;
    width: 40px;
    height: 18px;
    line-height: 12px;
    font-size: 14px;
    color: #fff;

    &:hover {
      color: #fff;
    }
  }
}

.home-service-title {
  position: relative;
  overflow: hidden;
  padding: 15px 20px;

  a {
    height: 19px;
    width: 19px;
    text-align: center;
    display: block;
    border: solid 1px #d2d2d2;
    line-height: 11px;

    i {
      font-size: 14px;
    }
  }

  &::after {
    content: "";
    position: absolute;
    height: 16px;
    width: 4px;
    background-color: #1890ff;
    border-radius: 15px;
    left: 4px;
    top: 24px;
  }
}

.swiper-home-politique {
  height: 420px;

  .swiper-slide {
    position: relative;
    width: auto;
    border-bottom: solid 1px #e8e8e8;
    // cursor: pointer;
    transition: all 0.3s;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      h4 {
        color: @primary-color;
      }
    }

    .ant-row {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      transform: translate(0, -50%);
    }

    .ant-tag {
      transition: none;
      height: 26px;
      line-height: 26px;
      font-size: 14px;
      color: #0e0e0e;
      border: navajowhite;
      width: auto !important;
      background-color: rgba(229, 229, 229, 1);

      &.ant-tag-has-color {
        color: #fff;
      }
    }
  }
}

.home-date-box {
  border-radius: 5px;
  background-color: #fafafa;
  width: 73px;
  height: 73px;
  text-align: center;

  >div {
    height: 100%;
  }

  .day {
    color: #000;
    line-height: 1.1;
  }
}

.home-park {
  .ant-list-split {
    .ant-list-items {
      a {
        &:last-child {
          .ant-list-item {
            margin-bottom: 0;
          }
        }
      }
    }

    .ant-list-item {
      padding: 16px;
      border-bottom: 0;
      cursor: pointer;
      margin-bottom: 35px;
      border-radius: 4px;

      .ant-typography {
        line-height: 28px;
        height: 86px;
      }

      .ant-list-item-meta-title {
        font-size: 22px;
        line-height: 25px;
        height: 50px;
        color: #000;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        /* autoprefixer: off */
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        margin-bottom: 0;
      }

      &:hover {
        .ant-list-item-meta-title {
          color: @primary-color  !important;
        }
      }
    }
  }
}

.home-dynamic-icon {
  line-height: 14px;

  i {
    vertical-align: bottom;
  }
}

.enterprises-list {
  position: relative;
  text-align: center;
  height: 245px;
}

.enterprises-list-row {
  display: inline-block;
  vertical-align: middle;
  width: 165px;
  text-align: center;
  transition: all 0.3s;
  height: 215px;
  padding-top: 12px;

  &:hover {
    padding: 0;

    i {
      background-color: @primary-color;
      color: #fff;
      height: 160px;
      width: 160px;
    }

    span {
      margin-top: 10px;
    }
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
  }

  i {
    position: relative;
    display: inline-block;
    width: 120px;
    height: 120px;
    background-color: #fafafa;
    border-radius: 50%;
    font-size: 40px;
    color: #333;
    transition: all 0.3s;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  span {
    display: block;
    line-height: 21px;
    font-size: 22px;
    margin-top: 30px;
    color: #000;
    transition: all 0.3s;

    &::before {
      content: "";
      position: relative;
      top: -5px;
      margin: 0 auto;
      width: 10px;
      height: 2px;
      background-color: @primary-color;
      display: none;
    }
  }
}

.notice-title {
  position: relative;
  height: 64px;
  line-height: 64px;
  width: 125px;
  color: #010101;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    height: 24px;
    width: 1px;
    background-color: #8c8c8c;
    transform: translate(-50%, -50%);
  }
}

.home-modal {
  .ant-modal-content {
    height: 100%;

    .ant-modal-body {
      height: calc(~"100% - 108px");
      overflow: auto;

      &::-webkit-scrollbar {
        width: 9px;
        height: 9px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: -2px 0 0 #fff inset, 1px 0 0 #fff inset, 0 -1px 0 rgba(255, 255, 255, 0.9) inset, 0 1px 0 rgba(255, 255, 255, 0.9) inset;
      }

      &::-webkit-scrollbar-track {
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0);
      }
    }
  }
}

@media (max-width: 1366px) {
  .home-box {
    zoom: 0.8;
  }
}

.a-title {
  color: black;
  font-size: 18px;

  &:hover {
    color: @primary-color;
  }
}

/*首页 -- end*/

/*新版首页*/
// 迷你简粗宋
/*@font-face {
    font-family: "nmbfont1";
    src: url("../fonts/font1.TTF");
}
// 迷你简综艺
@font-face {
    font-family: "nmbfont2";
    src: url("../fonts/font2.TTF") format("truetype");
}
// 造字工房映力黑规体
@font-face {
    font-family: "nmbfont3";
    src: url("../fonts/font3.otf") format("truetype");
}*/
.home-container {
  width: 1400px;
  margin: 0 auto;

  .box-l {
    width: 600px;
  }

  .box-c {
    width: 596px;
    margin-left: 18px;

  }

  .box-r {
    width: 168px;
    margin-left: 18px;

    .side-block {
      &:first-child {
        margin-top: 0;
      }
    }
  }
}

.w600 {
  width: 600px;
}

.w596 {
  width: 596px;
}

.w168 {
  width: 168px;
}

.home-news {
  width: 100%;
  height: 338px;
  padding: 0 10px 10px 0;
  background: url("../images/pudong/shadow1.png") no-repeat right bottom;

  .home-news-item {
    height: 328px;
    width: 100%;
  }

  .ant-carousel {
    width: 100%;
    height: 100%;


    .img-full {
      display: block;
      width: 100%;
      height: 100%;
    }

    div.images-auto {
      height: 328px;
      width: 100%;
      object-fit: none;
      background-size: 100% !important;
    }

    .slick-dots {
      right: 15px;
      left: auto;
      margin: 0;
      bottom: 22px;
      width: auto;

      li {
        button {
          opacity: 1;
          background-color: #fff2e5;
          border-radius: 3px;

          &:before {
            display: none;
          }
        }

        &.slick-active {
          button {
            background-color: #ffec3d;
          }
        }
      }
    }
  }

  .home-news-item {
    position: relative;

    .home-news-text {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 54px;
      line-height: 48px;
      padding: 6px 100px 0 15px;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
      font-size: 18px;
      color: @white-color;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      z-index: 1;
      width: 100%;
    }
  }
}

.home-matrix {
  width: 100%;
  height: 338px;
  padding: 0 10px 10px 0;
  background: url("../images/pudong/shadow2.png") no-repeat right bottom;

  .ant-tabs {
    background-color: #2cbccd;
    height: 100%;
    padding: 0 15px;

    .ant-tabs-nav .ant-tabs-tab {
      padding: 12px 0;
      font-size: 16px;
      color: @white-color;
      line-height: 32px;

      &.ant-tabs-tab-active .ant-tabs-tab-btn {
        font-size: 18px;
        text-shadow: 1px 1.732px 0 rgba(3, 65, 63, 0.32);
        color: @white-color;
      }

      .ant-tabs-tab-btn:focus,
      .ant-tabs-tab-remove:focus,
      .ant-tabs-tab-btn:active,
      .ant-tabs-tab-remove:active {
        color: @white-color;
      }
    }

    .ant-tabs-ink-bar {
      display: none !important;
    }
  }

  .ant-tabs-top>.ant-tabs-nav,
  .ant-tabs-bottom>.ant-tabs-nav,
  .ant-tabs-top>div>.ant-tabs-nav,
  .ant-tabs-bottom>div>.ant-tabs-nav {
    margin-bottom: 0;
  }

  .slick-prev,
  .slick-next {
    display: none !important;
  }

  .home-matrix-list {
    margin-top: 10px;

    .home-matrix-item {
      &:last-child {
        border-bottom: none;
      }
    }
  }

  .home-matrix-item {
    padding: 12px 0 12px 15px;
    border-bottom: #a2d0d6 dashed 1px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 19px;
      width: 0;
      height: 0;
      border-top: transparent solid 6px;
      border-bottom: transparent solid 6px;
      border-left: @white-color solid 6px;
    }
  }
}

.more-a {
  font-size: 16px;
  color: @white-color;
  cursor: pointer;
}

.home-study {
  width: 100%;
  height: 160px;
  padding: 0 10px 10px 0;
  background: url("../images/pudong/shadow3.png") no-repeat right bottom;

  .ant-carousel {
    width: 100%;
    height: 100%;

    .slick-dots {
      margin: 0;

      li {
        button {
          opacity: 1;
          background-color: #fff2e5;
          border-radius: 3px;

          &:before {
            display: none;
          }
        }

        &.slick-active {
          button {
            background-color: #ffec3d;
          }
        }
      }
    }
  }
}

.blogroll {
  width: 100%;
  padding: 0 10px 10px 0;
  background: url("../images/pudong/shadow15.png") no-repeat right bottom;

  .blogroll-box {
    width: 100%;
    background-color: #21c4af;
    padding: 0 30px;

    .ant-row {
      min-height: 96px;
    }
  }
}

.footer-down {
  &.ant-dropdown-link {
    height: 38px;
    line-height: 38px;
    border: #fff solid 1px;
    color: #fff;
    display: block;
    width: 100%;
    padding: 0 30px 0 12px;
    position: relative;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    border-radius: 3px;

    .icon {
      position: absolute;
      right: 12px;
      top: 0;
      font-size: 14px;
    }
  }
}

.ant-dropdown.footer-down-overlay {
  .ant-dropdown-menu {
    max-height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.home-video {
  width: 100%;
  height: 160px;
  padding: 0 10px 10px 0;
  background: url("../images/pudong/shadow9.png") no-repeat right bottom;

  video {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
}

.home-declare {
  width: 100%;
  height: 160px;
  padding: 0 10px 10px 0;
  background: url("../images/pudong/shadow4.png") no-repeat right bottom;

  .home-declare-box {
    padding: 16px;
    background-color: #36cfc9;
    height: 100%;
  }

  &.declare2 {
    background: url("../images/pudong/shadow5.png") no-repeat right bottom;

    .home-declare-box {
      background-color: #85a5ff;
    }

    .home-declare-row {
      background-color: rgba(85, 111, 181, 0.32);
      box-shadow: inset 0 3px 5px 0 rgba(10, 83, 176, 0.15);
    }
  }
}

.home-declare-row {
  width: 100%;
  padding: 15px;
  height: 100%;
  border-radius: 6px;
  background-color: rgba(0, 158, 150, 0.32);
  box-shadow: inset 0 3px 5px 0 rgba(10, 83, 176, 0.15);

  div.images-auto {
    margin: 0;
  }

  .home-declare-chi {
    font-size: 18px;
    color: @white-color;
    text-shadow: 1px 1.732px 0 rgba(4, 60, 59, 0.26);
    letter-spacing: 2px;
  }

  .home-declare-eng {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.56);
    line-height: 1.3;
    letter-spacing: 1px;
  }
}

.home-lead {
  width: 100%;
  min-height: 220px;
  padding: 0 10px 10px 0;
  background: url("../images/pudong/shadow6.png") no-repeat right bottom;

  .home-lead-box {
    height: 100%;
    background-color: #55bbfa;
    padding: 15px 20px;

    .ant-row {
      height: 100%;
    }
  }

  .home-lead-title {
    width: 70px;
    border-right: rgba(255, 255, 255, 0.36) solid 2px;
    text-align: center;
    color: @white-color;
    height: 162px;
    padding-top: 20px;

    .icon {
      font-size: 50px;
      text-shadow: 1px 1.732px 0 rgba(0, 0, 0, 0.26);
      line-height: 1.2;
    }

    img {
      margin-top: 10px;
    }
  }

  .home-lead-item {
    width: 130px;
    height: 74px;
    text-align: center;
    line-height: 74px;
    background-color: rgba(50, 172, 231, 0.42);
    box-shadow: inset 0 3px 5px 0 rgba(10, 83, 176, 0.21);
    border-radius: 10px;
    margin: 8px 20px 8px 0;
  }
}

.home-poly {

  background: url("../images/pudong/shadow7.png") no-repeat right bottom;

  .home-lead-box {
    background-color: #39e3b0;
  }

  .home-group-list {
    width: 442px;
    margin-left: 20px;
  }

  .home-lead-item {
    width: 100%;
    height: 42px;
    line-height: 42px;
    background-color: rgba(0, 142, 114, 0.439);
    box-shadow: inset 0 3px 5px 0 rgba(10, 83, 176, 0.22);
    margin: 9px 0 9px 0;
    color: @white-color;
    text-shadow: 0.5px 0.866px 0 rgba(8, 0, 107, 0.4);
    font-size: 16px;
  }
}

.home-group {
  background: url("../images/pudong/shadow8.png") no-repeat right bottom;

  .home-lead-box {
    background-color: #51cff5;
    padding: 24px 20px;
  }

  .home-lead-item {
    width: 100%;
    height: 60px;
    background-color: transparent;
    box-shadow: none;
    margin: 8px 0px;
    color: @white-color;
    font-size: 16px;
    line-height: 1.2;

    .group-icon {
      width: 60px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      border-radius: 50%;
      background-color: rgba(0, 104, 183, 0.439);
      box-shadow: inset 0px 3px 5px 0px rgba(10, 83, 176, 0.2);
      margin-right: 5px;
    }

    .group-name {
      width: 80px;
      text-align: left;
      text-shadow: 0.5px 0.866px 0px rgba(8, 0, 107, 0.3);
    }
  }
}

.side-block {
  width: 100%;
  height: 109px;
  padding: 0 10px 10px 0;
  background: url("../images/pudong/shadow10.png") no-repeat right bottom;
  margin-top: 18px;

  .side-block-box {
    height: 100%;
    background-color: #74d6a8;
    text-align: center;
    color: @white-color;
    padding-top: 10px;

    .icon {
      font-size: 40px;
      text-shadow: 1px 1.732px 0 rgba(0, 0, 0, 0.26);
      line-height: 1.2;
    }

    .side-block-txt {
      font-size: 18px;
      text-shadow: 1px 1.732px 0 rgba(0, 0, 0, 0.26);
      letter-spacing: 2px;
    }
  }

  &.side2 {
    background: url("../images/pudong/shadow11.png") no-repeat right bottom;

    .side-block-box {
      background-color: #fac146;
    }
  }

  &.side3 {
    background: url("../images/pudong/shadow12.png") no-repeat right bottom;

    .side-block-box {
      background-color: #0fbff5;
    }
  }

  &.side4 {
    background: url("../images/pudong/shadow13.png") no-repeat right bottom;

    .side-block-box {
      background-color: #6b93ff;
    }
  }

  &.side5 {
    background: url("../images/pudong/shadow14.png") no-repeat right bottom;

    .side-block-box {
      background-color: #81db76;
    }
  }

  &.side6 {
    background: url("../images/pudong/shadow16.png") no-repeat right bottom;

    .side-block-box {
      background-color: #36cfc9;
    }
  }

  &.side7 {
    background: url("../images/pudong/shadow17.png") no-repeat right bottom;

    .side-block-box {
      background-color: #85a5ff;
    }
  }
}

.letter0 {
  letter-spacing: 0 !important;
}

.home-header {
  .container {
    max-width: 1400px;
    min-width: 1400px;
  }
}

/*新版首页end*/
@media screen and (max-width: 1400px) and (min-width: 1200px) {
  .home-body {
    height: 900px;
    overflow: hidden;
  }

  .home-container {
    position: relative;
    left: 50%;
    transform: scale(0.857) translateX(-50%);
    transform-origin: left top;
  }

  .home-header {
    .container {
      max-width: 1200px;
      min-width: 1200px;
    }
  }
}