.loading-spin-box{
   position: fixed;
   top: 0;
   left: 0;
   display: flex;
   width: 100%;
   height: 100vh;
   align-items: center;
   justify-content: center;
   background-color: rgba(255,255,255,0);
   .ant-spin{
      color: #ffffff;
      .ant-spin-text{
         margin-top: 8px;
      }
   }
   .loading-close{
      position: absolute;
      right: 24px;
      top: 24px;
      font-size: 22px;
      color: #FFFFFF;
      cursor: pointer;
   }
}
