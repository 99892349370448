@import "themes/default";

.declare-logo {
  height: 85px;

  img {
    max-height: 100%;
  }
}

.declare-header {
  display: flex;
  align-items: center;
  height: 87px;
  border-top: 2px solid #f29221;
  background-color: #aae3f0;
}

.declare-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1100px;
  margin: 0 auto;
  height: 87px;
}


.declare-footer {
  position: relative;
  height: 90px;
  border-top: 10px solid #f29221;
  text-align: center;
  font-size: 14px;
  color: #000;
  z-index: 1;
}

.declare-footer-container {
  width: 1100px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.declare-content {
  position: relative;
  background: url(../images/declare/jjsb_bg3.jpeg) center top /100% auto;
  overflow: hidden;
  min-height: calc(~'100vh - 87px');

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 532px;
    background: url(../images/declare/jjsb_bg.jpeg) no-repeat center top / 100% auto;
    z-index: 1;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 930px;
    background: url(../images/declare/jjsb_bg2.jpeg) no-repeat center bottom / 100% auto;
  }
}

.declare-banner {
  position: relative;
  height: 331px;
  background: url(../images/declare/banner.png) no-repeat center top;
  z-index: 2;
}

.z-Index {
  position: relative;
  z-index: 2;
}

.declare-box {
  position: relative;
  background-color: #fff;
  width: 1100px;
  margin: -18px auto 0;
  z-index: 1;
  overflow: hidden;

  > .ant-row {
    height: 100%;
    align-items: stretch;

    > .ant-col-5 {
      flex: 0 0 25%;
      max-width: 25%;
    }

    > .ant-col-19 {
      flex: 0 0 75%;
      max-width: 75%;
    }
  }
}

.side-menu {
  border-right: 1px solid #e6e6e6;
  background-color: #fafafa;
  min-height: 650px;
  height: 100%;
}

.information-infos {
  padding: 25px 15px 15px;
  //border-bottom: 1px solid #1073be;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 0;

  .information-name {
    font-size: 16px;
  }
}

.ant-menu.declare-menu {
  background-color: transparent;
  border-right: none;

  .ant-menu-item {
    border-bottom: #e6e6e6 solid 1px;
    margin: 0 !important;
    height: 50px;
    line-height: 50px;
    overflow: visible;
    font-size: 16px;

    img {
      width: 24px;
      margin-right: 10px;
    }
  }

  .ant-menu-submenu {
    border-bottom: #e6e6e6 solid 1px;

    .ant-menu-submenu-title {
      margin: 0;
      height: 50px;
      line-height: 50px;
      font-size: 16px;

      .icon {
        margin-right: 10px;
      }

      img {
        width: 24px;
        margin-right: 10px;
      }
    }

    .ant-menu-item {
      border-bottom: none;
      font-size: 14px;

      .icon {
        display: inline-block;
        width: 8px;
        height: 8px;
        background-color: #ddd;
        vertical-align: 1px;

        &:before {
          display: none;
        }
      }

      &:hover {
        .icon {
          background-color: @primary-color;
        }
      }

      &.ant-menu-item-selected {
        background: none !important;
        color: @primary-color;

        &:before, &:after {
          display: none;
        }

        .icon {
          background-color: @primary-color;
        }
      }
    }

    .ant-menu {
      background-color: #f5f5f5;
    }

    &.ant-menu-submenu-selected {
      .ant-menu-submenu-title {
        color: @white-color;
        background: linear-gradient(to bottom, #1e91e9, #1483d8) !important;
        overflow: visible;

        &:after {
          content: "";
          position: absolute;
          right: -6px;
          height: 49px;
          width: 6px;
          background: linear-gradient(to bottom, #1e91e9, #1483d8) !important;
          border: none;
        }

        &:before {
          content: "";
          width: 0;
          height: 0;
          position: absolute;
          right: -6px;
          top: 49px;
          border-right: transparent solid 6px;
          border-left: transparent solid 0;
          border-top: #1483d8 solid 4px;
        }

        .ant-menu-submenu-arrow {
          color: @white-color;
        }
      }
    }
  }

  .ant-menu-item:hover, .ant-menu-submenu-title:hover {
    color: @primary-color !important;
  }

  .ant-menu-item-selected {
    background: linear-gradient(to bottom, #1e91e9, #1483d8) !important;

    &:after {
      right: -6px;
      height: 49px;
      width: 6px;
      background: linear-gradient(to bottom, #1e91e9, #1483d8) !important;
      border: none;
    }

    &:before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      right: -6px;
      top: 49px;
      border-right: transparent solid 6px;
      border-left: transparent solid 0;
      border-top: #1483d8 solid 4px;
    }

    &:hover {
      color: @white-color !important;
    }
  }
}

.declare-right {
  padding: 20px 40px 20px 10px;
  min-height: 800px;
}

.declare-title {
  font-size: 20px;
  line-height: 40px;
  height: 40px;
  border-bottom: 1px solid #d5382f;

  img {
    margin-right: 5px;
  }
}

.foundation-intro {
  img {
    width: 120px;
  }
}

.foundation-intro-write {
  font-size: 14px;
  line-height: 2;
  color: @gray-color;
}

.feature-cl {
  position: relative;

  .ant-carousel {

    .slick-dots {
      position: relative;
      bottom: 0;
      margin: 25px auto 0;

      li {
        width: 140px;
        height: 90px;
        margin: 0 6px;
        border: @white-color solid 2px;
        overflow: hidden;
        cursor: pointer;

        &.slick-active {
          width: 140px;
          border-color: #fdc236;
          border-radius: 4px;

          .img-link {
            transform: scale(1.15);
            border-radius: 4px;
          }
        }
      }

      .img-link {
        width: 100%;
        height: 100%;
        background: no-repeat center;
        background-size: cover;
        transition: all 0.25s;
      }
    }
  }

  .feature-arrow {
    position: absolute;
    width: 24px;
    height: 54px;
    line-height: 54px;
    border: 1px solid #d6d6d6;
    border-radius: 3px;
    background-color: #fafafa;
    color: #d22727;
    bottom: 20px;
    text-align: center;
    font-weight: bold;
    z-index: 20;
    cursor: pointer;

    &:hover {
      background-color: #d6d6d6;
    }

    &.left {
      left: 30px;
    }

    &.right {
      right: 30px;
    }
  }
}

.feature-cl-desc {
  line-height: 2;
  text-indent: 2em;
}

.donation-write {
  line-height: 2;
  font-size: 16px;

  .tel {
    font-family: Arial;
    color: #ca0000;
  }
}

.zfb-code {
  img {
    width: 120px;
    height: 120px;
  }
}

.zfb-code-word {
  p {
    margin: 0;
  }
}

.donation-tips {
  font-size: 14px;
  text-align: center;

  span {
    margin: 0 30px;

    .icon {
      font-size: 14px;
      margin-right: 5px;
      color: @gray-three-color;
    }
  }
}

/*关于我们*/
.path-title {
  border-bottom: @gray-color solid 1px;
  padding-bottom: 5px;

  .path-name {
    font-size: 20px;
    color: @gray-one-color;
  }

  .crumb-box {
    padding: 0;
  }
}

.park-word {
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 1.8;
}

/*关于我们end*/

/*信息公开*/
.title-a {
  color: @gray-one-color;

  &:hover {
    color: @primary-color;
  }
}

/*信息公开end*/

/*项目展示*/
.hot-image {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 46px;
    height: 46px;
    background: url("../images/declare/new.png") no-repeat;
  }
}

/*项目展示end*/

/*政策申报*/
body .card-body-0 .ant-card-body {
  padding: 0;
}

.page-components.declare-page-components {
  border: #e6e6e6 solid 1px;

  .page-components-rows {
    min-height: 60px;

    .page-components-rows-label {
      line-height: 60px;
    }
  }
}

/*政策申报end*/
