@import "themes/default";

/*筛选*/
.page-components {
  position: relative;
  .ant-card{
    border: none;
    border-top: 1px solid #F0F0F0;
    border-bottom: 1px solid #F0F0F0;
    .ant-card-body{
      padding: 0;
    }
  }
  .ant-input{
    height: 40px;
  }
  .ant-btn{
    height: 40px;
  }
  .page-components-rows-label{
    width: 111px;
  }

  .page-components-rows {
    min-height: 72px;
    align-items: center;
    position: relative;
    display: flex;
    width: 100%;
    border-bottom: 1px dashed #e8e8e8;
    &::after{
      content: "";
      position: absolute;
      background-color: #FAFAFA;
      width: 111px;
      height: 100%;
      left: 0;
      z-index: 0;
    }


    .page-components-rows-label {
      flex: 0 0 auto;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      text-align: center;
      width: 111px;
      line-height: 72px;
      background-color: #FAFAFA;
      z-index: 1;
      .condition-name{
        font-size: 16px;
        color: #000000;
      }
    }

    .page-components-rows-content {
      flex: 1 1;
      max-height: 72px;
      overflow: hidden;
      padding-left: 24px;
    }

    &.page-components-rows-last, &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border: none;
    }
  }

  .page-components-rows-select {
    position: relative;
    margin-left: -8px;
    overflow: hidden;
    line-height: 32px;
    transition: all 0.3s;
    user-select: none;
    padding: 10px 0;

    .ant-tag {
      margin-right: 24px;
      padding: 0 8px;
      font-size: 14px;
    }
  }
}
.small-card .ant-card-body {
  padding: 16px 24px;
}
.select-height{
  .ant-btn{
    position: absolute;
    top: 23px;
    right: 0px;
    height:  auto;
  }
}

/*筛选end*/
.show-content{
  height: auto !important;
  // max-height: unset !important;
  max-height: inherit !important;
}
