@import "themes/default";

.phone-menu {
  background-color: @primary-color;
  color: @white-color;
  border-radius: 0 4px 4px 0;
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  height: 36px;
  width: 20px;
  text-align: center;
  line-height: 36px;
  display: none;

  &.type2 {
    right: 0;
    left: auto;
    transform: rotateZ(180deg);
  }
}

.logo-m {
  display: flex;
  align-items: center;

  img {
    display: block;
    height: 30px;
    margin-right: 7px;
  }

  span {
    display: block;
    font-size: 14px;
    color: #fff;
    line-height: 1.1;
  }
}

@media (max-width: 1200px) {
  .content {

    >.container {
      padding: 0 16px;
      margin-bottom: 16px;
    }

    .form-content {
      width: auto;
      padding: 16px;
    }
  }

  .ant-list-item-meta {
    flex-direction: column;

    .list-body {
      .pt8 {
        padding-top: 0 !important;
      }
    }

    .ant-row {
      &.mt12 {
        margin-top: 0 !important;
      }
    }

    .ant-list-item-meta-avatar {
      margin-right: 0;
      margin-bottom: 16px;
    }

    .ant-list-item-meta-content {
      width: auto;
    }
  }

  .view.container-page.slideInRight {
    animation-name: none;
  }

  html>body {
    min-width: 0;
    -webkit-overflow-scrolling: touch;
  }

  .root {
    overflow-x: hidden;
    height: auto;
    min-height: 100%;
  }

  .container {
    max-width: none;
    min-width: 0;
  }

  .declare-container {
    width: auto;
  }

  .anchor,
  .home-ancre {
    display: none;
  }

  .ant-back-top.home-backTop {
    right: 10px;
    top: auto;
    bottom: 20px;
  }

  //导航菜单
  .header-affix {
    .ant-affix {
      z-index: 100;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    }
  }

  .index-header {
    display: block;
  }

  .header-mobile-menu {
    width: 16px;
    height: 48px;
    cursor: pointer;
    position: absolute;
    top: 2px;
    right: 10px;
    z-index: 102;
    display: block;
    padding-top: 16px;

    em {
      display: block;
      width: 100%;
      height: 2px;
      background: #fff;
      margin-top: 4px;
      transition: transform .3s cubic-bezier(.645, .045, .355, 1), opacity .3s cubic-bezier(.645, .045, .355, 1)
    }

    :first-child {
      margin-top: 0
    }
  }

  .second-header {
    height: 50px;

    .header-content {
      padding: 0 15px !important;
      border-left-width: 0;
      border-right-width: 0;
      border-bottom-width: 0;
      border-top-width: 2px;
      position: relative;

      .user-info {
        line-height: inherit;
        font-size: 13px;
        margin-top: 0;

        .info-img {
          width: 24px;
          height: 24px;
          margin-right: 6px;
          vertical-align: -7px;
        }

        .name {
          max-width: 120px;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .logo {
      height: 30px;

      img {
        height: 100%;
        display: block;
      }
    }

    .header-search {
      display: none;
    }

    .header-row {
      height: 50px;
      padding-right: 25px;
    }

    &.open {
      .header-mobile-menu em:nth-child(1) {
        transform: translateY(6px) rotate(45deg)
      }

      .header-mobile-menu em:nth-child(3) {
        transform: translateY(-6px) rotate(-45deg)
      }

      .header-mobile-menu em:nth-child(2) {
        opacity: 0
      }

      .nav {
        height: auto;
        opacity: 1;
      }
    }

    .ant-affix {
      position: static !important;
      width: auto !important;
      height: auto !important;
    }
  }

  .head-info {
    * {
      vertical-align: top;
      font-size: 13px;
    }

    .ant-col-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .info-name {
      display: inline-block;
      width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .margin-right {
      margin-right: 10px !important;
    }

    .icon {
      font-size: 13px;
      margin-right: 2px !important;
    }

    .user-info {
      >a {

        .icon,
        span {
          vertical-align: middle;
        }
      }
    }
  }

  .nav {
    height: 0;
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    opacity: 0;
    transition: opacity .3s cubic-bezier(.645, .045, .355, 1);
    z-index: 101;
    overflow: hidden;

    a {
      font-size: 14px;
    }

    .ant-menu-item,
    .ant-menu-submenu-title {
      font-size: 14px;
      text-align: left;
      margin: 0;
    }

    .ant-menu-inline .ant-menu-item {
      margin: 0;
    }

    .ant-menu-inline .ant-menu-item:not(:last-child) {
      margin-bottom: 0;
    }

    .ant-menu-inline .ant-menu-submenu-title {
      margin: 0;
    }
  }

  // 首页
  .home-container {
    width: auto;
    padding: 0 18px;

    .box-l {
      width: 100%;
    }

    .box-c {
      width: 100%;
      margin: 0;

      .home-lead {
        &:first-child {
          margin-top: 17px;
        }
      }
    }

    .box-r {
      width: 100%;
      margin: 0;
    }
  }

  .home-lead {
    background-size: cover;

    .home-lead-title {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: auto;
      padding: 0 0 10px 0;
      margin-bottom: 10px;
      border-bottom: rgba(255, 255, 255, 0.36) solid 2px;
      border-right: 0;

      img {
        margin-top: 3px;
        margin-left: 10px;
      }
    }

    .home-lead-item {
      width: 100%;
      margin-right: 0;
    }
  }

  .home-poly {
    .home-group-list {
      width: 100%;
      margin-left: 0;
    }
  }

  .home-group {
    .home-lead-item {
      height: 50px;

      .group-icon {
        width: 50px;
        height: 50px;
        line-height: 50px;

        img {
          width: 28px;
        }
      }

      .group-name {
        width: auto;
      }
    }
  }

  .home-video {
    margin-bottom: 18px;
  }

  .home-matrix .ant-tabs .ant-tabs-nav .ant-tabs-tab {
    margin-right: 16px;
  }

  .blogroll {
    background-size: cover;

    .blogroll-box {
      padding: 15px;
    }
  }

  .home-news {
    height: 238px;
    width: 100%;

    .home-news-item {
      height: 228px;
    }

    .ant-carousel {
      div.images-auto {
        height: 228px;
      }
    }
  }

  .justify-start {
    justify-content: flex-start !important;
  }

  .pd-footer span {
    display: block;
  }

  .menu-drawer {
    .ant-drawer-body {
      padding: 15px 20px;
    }
  }

  .ant-menu.my-menu {
    +.blank16 {
      height: 5px !important;
    }

    .ant-menu-item {
      height: 44px;
      line-height: 44px;
    }
  }

  .menu-drawer2 {
    .ant-drawer-body {
      padding: 0;
    }
  }

  .phone-menu {
    display: inline-block;
  }

  .report-table,
  .list-table.problem,
  .apart-table,
  .house-table,
  .my-report-table,
  .favorites-table,
  .iteam-relative-list,
  .table-fixed,
  .message-table,
  .list-table {
    overflow-x: auto;
    width: 100%;

    .ant-table-thead>tr>th,
    .ant-table-tbody>tr>td,
    .ant-table tfoot>tr>th,
    .ant-table tfoot>tr>td {
      white-space: nowrap;
    }
  }

  .paging .ant-pagination {
    white-space: nowrap;
  }

  .page-components {
    width: 100%;
    white-space: nowrap;
    overflow-x: auto;

    .page-components-rows {
      width: auto;
    }
  }

  .form-steps {
    zoom: 0.8;
  }

  .sortBox .sortButton {
    padding: 0 8px;
  }

  .content-map {
    height: calc(100vh - 50px);
  }

  .declare-logo {
    height: 40px;
  }

  .back-home {
    display: inline-block;
    margin-right: 10px;
    margin-top: 2px;
  }

  .declare-header-container {
    width: 100%;
  }

  .declare-footer-container {
    flex-direction: column;
    width: 100%;
  }

  .declare-box {
    width: 100%;
  }

  .declare-right {
    min-height: auto;
  }

  .declare-right {
    padding: 20px 10px;
  }

  .list-table {
    .ant-table table {
      table-layout: auto !important;
    }
  }
}