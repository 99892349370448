.images-auto {
    background: center no-repeat;
    background-size: cover;
    border-radius: 4px;
    img {
        display: none;
    }
}
.ReactModal__Overlay {
    z-index: 999;
}
.ReactModal__Content {
    .swiper-container {
        width: 100%;
        height: 100%;
    }
    .ant-modal-close {
        color: #ffffff;
        font-size: 28px;
        &:after{
            content: "\e625";
            font-family: "icon" !important;
            font-size: 18px;
            color: #ddd;
        }
    }
    .swiper-button-prev,
    .swiper-button-next {
        z-index: 99;
    }
    .swiper-pagination-bullet-active,
    .swiper-pagination-bullet {
        background-color: @white-color;
    }
}
