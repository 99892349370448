@import "themes/default";

.policydeal-header {
  position: relative;
  height: 110px;
  background-color: #485fad;
  display: flex;
  align-items: center;

  .title {
    span {
      display: block;
      font-size: 30px;
      text-shadow: 0px 3px 3px rgba(50, 44, 44, 0.24);
      letter-spacing: 1px;
      color: #fff;
    }

    em {
      display: block;
      font-size: 12px;
      color: #fff;
      letter-spacing: 1px;
      font-style: normal;
    }
  }

  .data {
    display: block;
    text-align: right;
    font-size: 16px;
    color: #fff;
  }
}

.policydeal-footer {
  position: relative;
  background-color: #536dc2;
  padding-top: 15px;
  color: #fff;

  ul,
  li {
    margin: 0;
    padding: 0 0 5px;
  }

  ul {
    padding-left: 20px;
  }

  .ant-typography,
  a {
    color: #ffffff;
  }

  .end-text {
    background-color: #485fad;
    font-size: 16px;
    padding: 10px 0;
  }
}

.policydeal-bg {
  position: relative;
  // background: url("../images/policydeal-bg.jpg") no-repeat center top #ced8ff;
  background-size: 100% auto;
}

.policydeal-menu {
  position: relative;

  .hd {
    position: relative;
    height: 136px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 15px;
    background-color: rgba(72, 95, 173, 0.9);

    >div {
      text-align: center;
    }
  }



  .text1 {
    font-size: 18px;
    color: #fff;
  }

  .text2 {
    font-size: 16px;
    color: #ffd50a;
    padding: 10px 0;
  }

  .text3 {
    color: rgba(255, 255, 255, 0.6);
    cursor: pointer;

    &:hover {
      color: #fff;
    }
  }

  .bd {
    background-color: rgba(255, 255, 255, 0.9);
  }
}

.policydeal-menu-box {
  position: relative;
  border-top: 1px solid #dcdcdc;
  padding: 15px 0;

  .row {
    position: relative;
    display: flex;
    align-items: center;
    height: 46px;
    padding: 0 30px;
    cursor: pointer;

    &:hover {
      background-color: rgba(72, 95, 173, 0.03);

    }

    &.active {
      background-color: rgba(72, 95, 173, 0.059);

      .icon,
      .tit {
        color: @primary-color;
      }

    }

    .icon {
      font-size: 18px;
      color: #a39d9e;
    }

    .tit {
      flex: 1;
      display: block;
      font-size: 16px;
      color: #010101;
      padding-left: 18px;
    }

    .lables {
      display: inline-block;
      padding: 0 7px;
      height: 20px;
      border-radius: 3px;
      text-align: center;
      line-height: 20px;
      color: #fff;
      font-size: 12px;
      background-color: #df4c41;
    }

    .dot {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 8px;
      background-color: #df4c41;
    }
  }
}

.policydeal-table {
  position: relative;

  .ant-table-thead>tr>th,
  .ant-table-tbody>tr>td {
    font-size: 16px;
  }

  .ant-table-pagination.ant-pagination {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    float: none;
  }
}

.ant-btn {
  &.blue-btn {
    border-color: @primary-color;
    color: @primary-color;

    &:hover {
      background-color: @primary-color;
      color: #fff;
    }
  }
}

.page-components-rows-more {
  position: absolute;
  right: 25px;
  top: 18px;
  color: @primary-color;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:after {
    content: "";
    position: relative;
    top: -2px;
    display: inline-block;
    height: 10px;
    width: 10px;
    border-width: 1px 1px 0 0;
    border-color: @primary-color;
    border-style: solid;
    transition: all 0.3s;
    margin-left: 5px;
    transform: matrix(-0.707107, 0.707107, -0.707107, -0.707107, 0, 0);
  }

  &.upstyle {
    &:after {
      top: 3px;
      transform: matrix(0.707107, -0.707107, 0.707107, 0.707107, 0, 0);
    }
  }
}

.page-components {
  &.policydeal-sytle {
    position: relative;
    border: 1px solid #e8e8e8;

    .page-components-rows {
      margin: 0;
      padding: 0;
      border-bottom-style: solid;

      &:last-child {
        border-bottom: none;
      }

      .page-components-rows-label {
        padding: 12px;
        width: 100px;
        background-color: #f8f8f8;
      }

      .page-components-rows-content {
        position: relative;
        padding: 12px 100px 12px 0;

        .page-components-rows-select {
          max-height: 35px;
          transition: all 0.3s;

          &.more-height {
            max-height: 350px;
          }
        }
      }
    }
  }
}

.warning-text {
  font-size: 14px;
  color: #f66400;
  background-color: #fff6eb;
  border-radius: 2px;
  padding: 10px;
  margin-top: 15px;
  line-height: 1.8;
}

.ant-row,
.ant-row-flex {
  &.register-form-head {
    padding: 8px 0;
    border-bottom: 1px solid #b2b2b2;

    .title {
      display: inline-block;
      font-size: 20px;

      &:before {
        content: '';
        position: relative;
        top: 1px;
        display: inline-block;
        width: 5px;
        height: 16px;
        background-color: #1188e4;
        margin-right: 7px;
        border-radius: 5px;
      }
    }
  }

  &.register-form-body {
    >.ant-col {
      border: 1px solid #e8e8e8;
      margin-left: -1px;
      margin-top: -1px;
    }


    .ant-form-item {
      margin-bottom: 0;
      font-size: 14px;
    }

    .ant-input-group-addon {
      font-size: 14px;
    }

    .ant-form-item-control {
      line-height: 1.6;
    }

    .ant-radio {
      position: relative;
      top: -1px;
    }

    .title {
      display: flex;
      font-size: 14px;
      line-height: 1.15;
      height: 100%;
      align-items: center;

      &.start {
        justify-content: flex-start;
      }

      i {
        display: inline-block;
        margin-right: 2px;
      }

      span {
        display: inline-block;
        color: #888;
        min-width: 5em;
      }
    }

    .ant-radio-wrapper,
    .ant-checkbox-wrapper {
      font-size: 14px;
      color: #888;
      margin: 3px 25px 3px 0;
    }

    .ant-input,
    .ant-select {
      font-size: 14px;
    }
  }
}

.form-lgstyle {
  position: relative;

  .form-lg-title {
    display: block;
    padding: 8px 0;
    font-size: 16px;
    border-bottom: 1px solid #b2b2b2;

    &:before {
      content: '';
      position: relative;
      top: 1px;
      display: inline-block;
      width: 5px;
      height: 16px;
      background-color: #1188e4;
      border-radius: 5px;
      margin-right: 7px;
    }

    &.type2 {
      border-bottom: none;
      font-size: 20px;
    }
  }

  .form-default-style .ant-form-item-label label {
    font-size: 14px;
  }

  .form-default-style .ant-input {
    font-size: 14px;
  }

  //.form-default-style .ant-form-item {
  //  margin-bottom: 5px;
  //}

  .details-tit {
    display: flex;
    align-items: flex-end;
    font-size: 15px;

    span {
      &:first-child {
        display: inline-block;
      }

      &:last-child {
        float: left;
      }
    }
  }
}

.ant-tooltip {
  &.phone-tooltip {

    .ant-tooltip-inner {
      background-color: #fff;
    }

    .ant-tooltip-arrow::before {
      background-color: #fff;
    }

    .bd {
      color: #333;
      padding: 5px;
    }
  }
}

.ant-card {
  .ant-card-head-title {
    padding-bottom: 10px;
  }

  &.mesgs-card {


    .text {
      font-size: 14px;
      color: #999;
      font-weight: normal;

      em {
        font-style: normal;
        color: #df4c41;
      }
    }
  }
}

.font-weight-bold {
  font-weight: bold;
}

.vertical-align-top {
  vertical-align: top;
}

.policydeal-form-title {
  display: flex;
  align-items: flex-start;
  font-size: 15px;

  span {
    &:first-child {
      display: inline-block;
    }

    &:last-child {
      flex: 1;
    }
  }

  &.type2 {
    display: block;
    line-height: 1.7;
  }
}

.borderRadius-none {
  .ant-select-selection {
    border-radius: 0;
  }
}

.review-table {
  position: relative;
  border: 1px solid #e6e6e6;
  width: 100%;
  margin-top: 15px;

  td {
    padding: 10px;
    border: 1px solid #e6e6e6;
    text-align: center;

    &.td-left {
      text-align: left;
    }
  }
}

.spin-all {

  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-spin-dot-item {
    background-color: #fff;
  }

  .ant-spin.ant-spin-show-text .ant-spin-text {
    color: #fff;
  }
}

.details-attach {
  position: relative;
  top: 5px;

  .ant-upload-list-picture-card-container {
    margin-bottom: 0;
  }
}

.last-divider {
  .ant-col {
    &:last-child {
      .ant-divider-dashed {
        display: none;
      }
    }
  }
}

.modal-lg {
  width: 720px !important;
}


/*滚动条样式*/
.applylog-list::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 4px;
}

.applylog-list::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

.applylog-list::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}