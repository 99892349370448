@import "themes/default";

.form-footer {
    position: relative;
    padding: 24px 0;
}
[data-theme="compact"] .ant-advanced-search-form,
.ant-advanced-search-form {
    padding: 24px;
    background: #fbfbfb;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

[data-theme="compact"] .ant-advanced-search-form .ant-form-item,
.ant-advanced-search-form .ant-form-item {
    display: flex;
}

[data-theme="compact"] .ant-advanced-search-form .ant-form-item-control-wrapper,
.ant-advanced-search-form .ant-form-item-control-wrapper {
    flex: 1;
}
.survey-list-form {
    &.label-row {
        padding: 0 6px;
    }
    .ant-form-item-control-input-content {
        h3 {
            position: relative;
            .color-red {
                position: absolute;
                left: -10px;
                top: 3px;
            }
            span {
                vertical-align: top;
            }
            p {
                display: inline-block;
                overflow: hidden;
                white-space: normal;
                margin: 0;
                width: calc(100% - 30px);
            }
        }
        h3 + input,
        h3 + textarea,
        .ant-select,
        .ant-picker {
            //width: calc(100% - 30px);
        }
    }
}
