@import "themes/default";

.content-back {
  position: relative;
  background: url("../images/pudong/body-bg.jpg") no-repeat center;
  background-size: cover;
}
.second-box{
  padding: 30px 0 25px;
  .second-block{
    background-color: @white-color;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 20px;
    position: relative;
    &:hover{
      .second-modal{
        opacity: 1;
      }
    }
  }
  .second-body{
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    padding: 0 15px;
    height: 62px;
    line-height: 62px;
    
    
    background-image: linear-gradient( 0deg, rgb(47,112,216) 0%, rgb(47,112,216) 46%, rgb(47,112,216) 47%, rgba(47,112,216,0.6) 100%);
    .ant-typography{
        color: #fff !important;
        font-size: 20px;
    }
    
  }
}
.second-modal{
  position: absolute;
  left: 0;
  top:0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
  z-index: 1;
  background-color: rgba(0,0,0,0.5);
  color:@white-color;
  opacity: 0;
  transition:all 0.5s;
  .second-modal-write{
    position: absolute;
    left: 50%;
    top:50%;
    transform: translate(-50%,-50%);
    z-index: 3;
    width: 100%;
    padding: 20px;
  }
  .second-desc{
    text-align: center;
    font-size: 24px;
    color: #fff;
    overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}
}

/*地图*/
.content-map{
  height: calc(~'100vh - 168px');
  position: relative;
}
.custom-marker {
  width: 10px;
  height: 10px;
  position: relative;

  .marker-ring {
    background-color: #f89230;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    p {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -20px;
      margin-top: -20px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      animation: myfirst 4s infinite;
      border: 2px solid #f89230;
      opacity: 0;
    }

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -15px;
      margin-top: -15px;
      display: block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      animation: myfirst 2s infinite;
      border: 2px solid #f89230;
      opacity: 0;
    }
  }

  &.ring1 {
    .marker-ring {
      background-color: #027ad6;

      p,
      span {
        border-color: #027ad6;
      }
    }
  }

  &.ring2 {
    .marker-ring {
      background-color: #bf3485;

      p,
      span {
        border-color: #bf3485;
      }
    }
  }

  &.ring3 {
    .marker-ring {
      background-color: #7ba60f;

      p,
      span {
        border-color: #7ba60f;
      }
    }
  }

  &.ring4 {
    .marker-ring {
      background-color: #f29727;

      p,
      span {
        border-color: #f29727;
      }
    }
  }
}
.map-type {
  position: absolute;
  left: 30px;
  bottom: 30px;
  width: 140px;
  background-color: #fff;
  padding: 15px;
  pointer-events: all;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);

  .row {
    display: flex;
    padding: 5px 0;
    align-items: center;

    img {
      display: inline-block;
      margin-right: 5px;
    }
    .custom-marker{
      margin-right: 12px;
      .marker-ring{
        p{
          width: 30px;
          height: 30px;
          margin: -15px 0 0 -15px;
        }
        span{
          width: 20px;
          height: 20px;
          margin: -10px 0 0 -10px;
        }
      }
    }
  }
}

@keyframes myfirst {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  60% {
    opacity: 0.8;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.map-list {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 360px;
  background-color: #fff;
  border-left: 1px solid #e8e8e8;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  pointer-events: all;
  z-index: 1;

  .hd {
    padding: 15px;
    border-bottom: 1px solid #e8e8e8;
  }

  .bd {
    flex: 1;
    overflow: auto;
    padding: 15px;
  }

  .ant-list-pagination {
    text-align: center;
  }
}
.map-box {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.maker-down{ background-color: #fff; box-shadow: #a2a2a2 0 0 5px; border-radius: 4px; z-index: 1; padding: 10px; position: absolute; width: 350px; left: 50%; bottom: 15px; transform: translateX(-50%);  display: none;}
.maker-down:after{ content: ""; position: absolute; left: 50%; transform: translateX(-50%); bottom:-8px; width: 0; height: 0; border-left: solid 8px transparent; border-right: solid 8px transparent; border-top: solid 8px #fff;}
.maker-down-title{font-size: 14px; max-height: 40px; overflow: hidden; line-height: 20px;}
.maker-down-type{font-size: 12px; color:#999;}
.maker-down-type .icon{font-size: 12px; margin-right: 2px;}
.custom-marker:hover{
  .maker-down{display: block;}
}
.amap-marker{
  &:hover{
    z-index: 101 !important;
  }
}
.marker-desc{
  margin-top: 6px;
  max-height: 60px;
  line-height: 20px;
  overflow-y: auto;
  font-size: 13px;
  color:@gray-two-color;
}
.maker-down-img {
  width: 120px;
  height: 95px;
  background: no-repeat center;
  background-size: cover;
  float: left;
  margin-right: 10px;
}
.marker-top{
  overflow: hidden;
}
/*地图end*/

