@import "animate.css";
/*动画库*/
/* theme-orman */
@import "~swiper/swiper-bundle.css";
.theme-orman.slider-wrapper {
  width: 100%;
  height: 480px;
  position: relative;
  margin: 0 auto;
}
.theme-orman .nivoSlider {
  position: relative;
  width: 100%;
  height: 480px;
  background-size: 100% 100% !important;
  background-color: #333 !important;
}
.form-nivo {
  background-color: #333 !important;
}
.theme-orman .nivoSlider img {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 480px;
}
.theme-orman .nivoSlider a {
  border: 0;
  display: block;
}
.theme-orman .nivo-controlNav {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  z-index: 101;
}
.theme-orman .nivo-controlNav a {
  display: block;
  width: 12px;
  height: 12px;
  border: 0;
  margin-right: 7px;
  float: left;
  border-radius: 50%;
  background-color: #ffffff;
  opacity: 0.7;
  text-indent: -99999px;
}
.theme-orman .nivo-controlNav a.active {
  opacity: 1;
}
.theme-orman .nivo-directionNav a {
  display: block;
  width: 25px;
  height: 100%;
  text-indent: -9999px;
  border: 0;
  top: 0;
}
.theme-orman a.nivo-nextNav {
  background: url("../images/arrow-right-light.png") center no-repeat;
  right: 10px;
  padding-right: 20px;
}
.theme-orman a.nivo-prevNav {
  background: url("../images/arrow-left-light.png") center no-repeat;
  left: 10px;
  padding-left: 20px;
}
.theme-orman .nivo-caption {
  font-family: Helvetica, Arial, sans-serif;
}
.theme-orman .nivo-caption a {
  color: #fff;
  border-bottom: 1px dotted #fff;
}
.theme-orman .nivo-caption a:hover {
  color: #fff;
}
.nivoSlider {
  position: relative;
}
.nivoSlider img {
  position: absolute;
  top: 0;
  left: 0;
}
.nivoSlider a.nivo-imageLink {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
  margin: 0;
  z-index: 6;
  display: none;
}
.nivo-slice {
  display: block;
  position: absolute;
  z-index: 5;
  height: 100%;
}
.nivo-box {
  display: block;
  position: absolute;
  z-index: 5;
}
.nivo-caption {
  position: absolute;
  left: 0;
  bottom: 0;
  background: #000;
  color: #fff;
  opacity: 0.5;
  width: 100%;
  z-index: 8;
}
.nivo-caption p {
  padding: 5px;
  margin: 0;
}
.nivo-caption a {
  display: inline !important;
}
.nivo-html-caption {
  display: none;
}
.nivo-directionNav a {
  position: absolute;
  top: 50%;
  z-index: 9;
  cursor: pointer;
}
.nivo-prevNav {
  left: 0;
}
.nivo-nextNav {
  right: 0;
}
.nivo-controlNav a {
  position: relative;
  z-index: 9;
  cursor: pointer;
}
.nivo-controlNav a.active {
  font-weight: bold;
}
