@import "./themes/default";

.bg-default {
  background-color: @primary-color !important;
}

.text-default {
  color: @primary-color !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.text-white {
  color: #fff !important;
}

.text-gray-light {
  color: #CCCCCC !important;
}

.text-gray {
  color: #999999 !important;
}

.text-gray-dark {
  color: #666666 !important;
}

.text-hover:hover {
  color: #1890ff !important;
}

.bg-red-light {
  background-color: #ff4d4f !important;
}

.bg-red {
  background-color: #f5222d !important;
}

.bg-red-dark {
  background-color: #cf1322 !important;
}

.bg-red-light-h:hover {
  background-color: #ff4d4f !important;
}

.bg-red-h:hover {
  background-color: #f5222d !important;
}

.bg-red-dark-h:hover {
  background-color: #cf1322 !important;
}

.text-red-light {
  color: #ff4d4f !important;
}

.text-red {
  color: #f5222d !important;
}

.text-red-dark {
  color: #cf1322 !important;
}

.bg-volcano-light {
  background-color: #ff7a45 !important;
}

.bg-volcano {
  background-color: #fa541c !important;
}

.bg-volcano-dark {
  background-color: #d4380d !important;
}

.bg-volcano-light-h:hover {
  background-color: #ff7a45 !important;
}

.bg-volcano-h:hover {
  background-color: #fa541c !important;
}

.bg-volcano-dark-h:hover {
  background-color: #d4380d !important;
}

.text-volcano-light {
  color: #ff7a45 !important;
}

.text-volcano {
  color: #fa541c !important;
}

.text-volcano-dark {
  color: #d4380d !important;
}

.bg-orange-light {
  background-color: #ffa940 !important;
}

.bg-orange {
  background-color: #fa8c16 !important;
}

.bg-orange-dark {
  background-color: #d46b08 !important;
}

.bg-orange-light-h:hover {
  background-color: #ffa940 !important;
}

.bg-orange-h:hover {
  background-color: #fa8c16 !important;
}

.bg-orange-dark-h:hover {
  background-color: #d46b08 !important;
}

.text-orange-light {
  color: #ffa940 !important;
}

.text-orange {
  color: #fa8c16 !important;
}

.text-orange-dark {
  color: #d46b08 !important;
}

.bg-gold-light {
  background-color: #ffc53d !important;
}

.bg-gold {
  background-color: #faad14 !important;
}

.bg-gold-dark {
  background-color: #d48806 !important;
}

.bg-gold-light-h:hover {
  background-color: #ffc53d !important;
}

.bg-gold-h:hover {
  background-color: #faad14 !important;
}

.bg-gold-dark-h:hover {
  background-color: #d48806 !important;
}

.text-gold-light {
  color: #ffc53d !important;
}

.text-gold {
  color: #faad14 !important;
}

.text-gold-dark {
  color: #d48806 !important;
}

.bg-yellow-light {
  background-color: #ffec3d !important;
}

.bg-yellow {
  background-color: #fadb14 !important;
}

.bg-yellow-dark {
  background-color: #d4b106 !important;
}

.bg-yellow-light-h:hover {
  background-color: #ffec3d !important;
}

.bg-yellow-h:hover {
  background-color: #fadb14 !important;
}

.bg-yellow-dark-h:hover {
  background-color: #d4b106 !important;
}

.text-yellow-light {
  color: #ffec3d !important;
}

.text-yellow {
  color: #fadb14 !important;
}

.text-yellow-dark {
  color: #d4b106 !important;
}

.bg-lime-light {
  background-color: #bae637 !important;
}

.bg-lime {
  background-color: #a0d911 !important;
}

.bg-lime-dark {
  background-color: #7cb305 !important;
}

.bg-lime-light-h:hover {
  background-color: #bae637 !important;
}

.bg-lime-h:hover {
  background-color: #a0d911 !important;
}

.bg-lime-dark-h:hover {
  background-color: #7cb305 !important;
}

.text-lime-light {
  color: #bae637 !important;
}

.text-lime {
  color: #a0d911 !important;
}

.text-lime-dark {
  color: #7cb305 !important;
}

.bg-green-light {
  background-color: #73d13d !important;
}

.bg-green {
  background-color: #52c41a !important;
}

.bg-green-dark {
  background-color: #389e0d !important;
}

.bg-green-light-h:hover {
  background-color: #73d13d !important;
}

.bg-green-h:hover {
  background-color: #52c41a !important;
}

.bg-green-dark-h:hover {
  background-color: #389e0d !important;
}

.text-green-light {
  color: #73d13d !important;
}

.text-green {
  color: #52c41a !important;
}

.text-green-dark {
  color: #389e0d !important;
}

.bg-cyan-light {
  background-color: #36cfc9 !important;
}

.bg-cyan {
  background-color: #13c2c2 !important;
}

.bg-cyan-dark {
  background-color: #08979c !important;
}

.bg-cyan-light-h:hover {
  background-color: #36cfc9 !important;
}

.bg-cyan-h:hover {
  background-color: #13c2c2 !important;
}

.bg-cyan-dark-h:hover {
  background-color: #08979c !important;
}

.text-cyan-light {
  color: #36cfc9 !important;
}

.text-cyan {
  color: #13c2c2 !important;
}

.text-cyan-dark {
  color: #08979c !important;
}

.bg-blue-light {
  background-color: #40a9ff !important;
}

.bg-blue {
  background-color: #1890ff !important;
}

.bg-blue-dark {
  background-color: #096dd9 !important;
}

.bg-blue-light-h:hover {
  background-color: #40a9ff !important;
}

.bg-blue-h:hover {
  background-color: #1890ff !important;
}

.bg-blue-dark-h:hover {
  background-color: #096dd9 !important;
}

.text-blue-light {
  color: #40a9ff !important;
}

.text-blue {
  color: #1890ff !important;
}

.text-blue-dark {
  color: #096dd9 !important;
}

.bg-geekblue-light {
  background-color: #597ef7 !important;
}

.bg-geekblue {
  background-color: #2f54eb !important;
}

.bg-geekblue-dark {
  background-color: #1d39c4 !important;
}

.bg-geekblue-light-h:hover {
  background-color: #597ef7 !important;
}

.bg-geekblue-h:hover {
  background-color: #2f54eb !important;
}

.bg-geekblue-dark-h:hover {
  background-color: #1d39c4 !important;
}

.text-geekblue-light {
  color: #597ef7 !important;
}

.text-geekblue {
  color: #2f54eb !important;
}

.text-geekblue-dark {
  color: #1d39c4 !important;
}

.bg-purple-light {
  background-color: #9254de !important;
}

.bg-purple {
  background-color: #722ed1 !important;
}

.bg-purple-dark {
  background-color: #531dab !important;
}

.bg-purple-light-h:hover {
  background-color: #9254de !important;
}

.bg-purple-h:hover {
  background-color: #722ed1 !important;
}

.bg-purple-dark-h:hover {
  background-color: #531dab !important;
}

.text-purple-light {
  color: #9254de !important;
}

.text-purple {
  color: #722ed1 !important;
}

.text-purple-dark {
  color: #531dab !important;
}

.bg-magenta-light {
  background-color: #f759ab !important;
}

.bg-magenta {
  background-color: #eb2f96 !important;
}

.bg-magenta-dark {
  background-color: #c41d7f !important;
}

.bg-magenta-light-h:hover {
  background-color: #f759ab !important;
}

.bg-magenta-h:hover {
  background-color: #eb2f96 !important;
}

.bg-magenta-dark-h:hover {
  background-color: #c41d7f !important;
}

.text-magenta-light {
  color: #f759ab !important;
}

.text-magenta {
  color: #eb2f96 !important;
}

.text-magenta-dark {
  color: #c41d7f !important;
}

.bg-grey-light {
  background-color: #d9d9d9 !important;
}

.bg-grey {
  background-color: #bfbfbf !important;
}

.bg-grey-dark {
  background-color: #8c8c8c !important;
}

.bg-grey-light-h:hover {
  background-color: #d9d9d9 !important;
}

.bg-grey-h:hover {
  background-color: #bfbfbf !important;
}

.bg-grey-dark-h:hover {
  background-color: #8c8c8c !important;
}

.text-grey-light {
  color: #d9d9d9 !important;
}

.text-grey {
  color: #bfbfbf !important;
}

.text-grey-dark {
  color: #8c8c8c !important;
}

.bg-primary-light {
  background-color: #40a9ff !important;
}

.bg-primary {
  background-color: #1890ff !important;
}

.bg-primary-dark {
  background-color: #096dd9 !important;
}

.bg-primary-light-h:hover {
  background-color: #40a9ff !important;
}

.bg-primary-h:hover {
  background-color: #1890ff !important;
}

.bg-primary-dark-h:hover {
  background-color: #096dd9 !important;
}

.text-primary-light {
  color: #40a9ff !important;
}

.text-primary {
  color: #1890ff !important;
}

.text-primary-dark {
  color: #096dd9 !important;
}

.bg-success-light {
  background-color: #73d13d !important;
}

.bg-success {
  background-color: #52c41a !important;
}

.bg-success-dark {
  background-color: #389e0d !important;
}

.bg-success-light-h:hover {
  background-color: #73d13d !important;
}

.bg-success-h:hover {
  background-color: #52c41a !important;
}

.bg-success-dark-h:hover {
  background-color: #389e0d !important;
}

.text-success-light {
  color: #73d13d !important;
}

.text-success {
  color: #52c41a !important;
}

.text-success-dark {
  color: #389e0d !important;
}

.bg-error-light {
  background-color: #ff4d4f !important;
}

.bg-error {
  background-color: #f5222d !important;
}

.bg-error-dark {
  background-color: #cf1322 !important;
}

.bg-error-light-h:hover {
  background-color: #ff4d4f !important;
}

.bg-error-h:hover {
  background-color: #f5222d !important;
}

.bg-error-dark-h:hover {
  background-color: #cf1322 !important;
}

.text-error-light {
  color: #ff4d4f !important;
}

.text-error {
  color: #f5222d !important;
}

.text-error-dark {
  color: #cf1322 !important;
}

.bg-warning-light {
  background-color: #ffec3d !important;
}

.bg-warning {
  background-color: #fadb14 !important;
}

.bg-warning-dark {
  background-color: #d4b106 !important;
}

.bg-warning-light-h:hover {
  background-color: #ffec3d !important;
}

.bg-warning-h:hover {
  background-color: #fadb14 !important;
}

.bg-warning-dark-h:hover {
  background-color: #d4b106 !important;
}

.text-warning-light {
  color: #ffec3d !important;
}

.text-warning {
  color: #fadb14 !important;
}

.text-warning-dark {
  color: #d4b106 !important;
}

.bg-grey-lighter {
  background-color: #f5f5f5 !important;
}

.bg-grey-lighter-h:hover {
  background-color: #f5f5f5 !important;
}

.text-grey-lighter {
  color: #f5f5f5 !important;
}

.bg-grey-darker {
  background-color: #262626 !important;
}

.bg-grey-darker-h:hover {
  background-color: #262626 !important;
}

.text-grey-darker {
  color: #262626 !important;
}

.bg-red-1 {
  background-color: #fff1f0 !important;
}

.bg-red-1-h:hover {
  background-color: #fff1f0 !important;
}

.text-red-1 {
  color: #fff1f0 !important;
}

.bg-red-2 {
  background-color: #ffccc7 !important;
}

.bg-red-2-h:hover {
  background-color: #ffccc7 !important;
}

.text-red-2 {
  color: #ffccc7 !important;
}

.bg-red-3 {
  background-color: #ffa39e !important;
}

.bg-red-3-h:hover {
  background-color: #ffa39e !important;
}

.text-red-3 {
  color: #ffa39e !important;
}

.bg-red-4 {
  background-color: #ff7875 !important;
}

.bg-red-4-h:hover {
  background-color: #ff7875 !important;
}

.text-red-4 {
  color: #ff7875 !important;
}

.bg-red-5 {
  background-color: #ff4d4f !important;
}

.bg-red-5-h:hover {
  background-color: #ff4d4f !important;
}

.text-red-5 {
  color: #ff4d4f !important;
}

.bg-red-6 {
  background-color: #f5222d !important;
}

.bg-red-6-h:hover {
  background-color: #f5222d !important;
}

.text-red-6 {
  color: #f5222d !important;
}

.bg-red-7 {
  background-color: #cf1322 !important;
}

.bg-red-7-h:hover {
  background-color: #cf1322 !important;
}

.text-red-7 {
  color: #cf1322 !important;
}

.bg-red-8 {
  background-color: #a8071a !important;
}

.bg-red-8-h:hover {
  background-color: #a8071a !important;
}

.text-red-8 {
  color: #a8071a !important;
}

.bg-red-9 {
  background-color: #820014 !important;
}

.bg-red-9-h:hover {
  background-color: #820014 !important;
}

.text-red-9 {
  color: #820014 !important;
}

.bg-red-10 {
  background-color: #5c0011 !important;
}

.bg-red-10-h:hover {
  background-color: #5c0011 !important;
}

.text-red-10 {
  color: #5c0011 !important;
}

.bg-volcano-1 {
  background-color: #fff2e8 !important;
}

.bg-volcano-1-h:hover {
  background-color: #fff2e8 !important;
}

.text-volcano-1 {
  color: #fff2e8 !important;
}

.bg-volcano-2 {
  background-color: #ffd8bf !important;
}

.bg-volcano-2-h:hover {
  background-color: #ffd8bf !important;
}

.text-volcano-2 {
  color: #ffd8bf !important;
}

.bg-volcano-3 {
  background-color: #ffbb96 !important;
}

.bg-volcano-3-h:hover {
  background-color: #ffbb96 !important;
}

.text-volcano-3 {
  color: #ffbb96 !important;
}

.bg-volcano-4 {
  background-color: #ff9c6e !important;
}

.bg-volcano-4-h:hover {
  background-color: #ff9c6e !important;
}

.text-volcano-4 {
  color: #ff9c6e !important;
}

.bg-volcano-5 {
  background-color: #ff7a45 !important;
}

.bg-volcano-5-h:hover {
  background-color: #ff7a45 !important;
}

.text-volcano-5 {
  color: #ff7a45 !important;
}

.bg-volcano-6 {
  background-color: #fa541c !important;
}

.bg-volcano-6-h:hover {
  background-color: #fa541c !important;
}

.text-volcano-6 {
  color: #fa541c !important;
}

.bg-volcano-7 {
  background-color: #d4380d !important;
}

.bg-volcano-7-h:hover {
  background-color: #d4380d !important;
}

.text-volcano-7 {
  color: #d4380d !important;
}

.bg-volcano-8 {
  background-color: #ad2102 !important;
}

.bg-volcano-8-h:hover {
  background-color: #ad2102 !important;
}

.text-volcano-8 {
  color: #ad2102 !important;
}

.bg-volcano-9 {
  background-color: #871400 !important;
}

.bg-volcano-9-h:hover {
  background-color: #871400 !important;
}

.text-volcano-9 {
  color: #871400 !important;
}

.bg-volcano-10 {
  background-color: #610b00 !important;
}

.bg-volcano-10-h:hover {
  background-color: #610b00 !important;
}

.text-volcano-10 {
  color: #610b00 !important;
}

.bg-orange-1 {
  background-color: #fff7e6 !important;
}

.bg-orange-1-h:hover {
  background-color: #fff7e6 !important;
}

.text-orange-1 {
  color: #fff7e6 !important;
}

.bg-orange-2 {
  background-color: #ffe7ba !important;
}

.bg-orange-2-h:hover {
  background-color: #ffe7ba !important;
}

.text-orange-2 {
  color: #ffe7ba !important;
}

.bg-orange-3 {
  background-color: #ffd591 !important;
}

.bg-orange-3-h:hover {
  background-color: #ffd591 !important;
}

.text-orange-3 {
  color: #ffd591 !important;
}

.bg-orange-4 {
  background-color: #ffc069 !important;
}

.bg-orange-4-h:hover {
  background-color: #ffc069 !important;
}

.text-orange-4 {
  color: #ffc069 !important;
}

.bg-orange-5 {
  background-color: #ffa940 !important;
}

.bg-orange-5-h:hover {
  background-color: #ffa940 !important;
}

.text-orange-5 {
  color: #ffa940 !important;
}

.bg-orange-6 {
  background-color: #fa8c16 !important;
}

.bg-orange-6-h:hover {
  background-color: #fa8c16 !important;
}

.text-orange-6 {
  color: #fa8c16 !important;
}

.bg-orange-7 {
  background-color: #d46b08 !important;
}

.bg-orange-7-h:hover {
  background-color: #d46b08 !important;
}

.text-orange-7 {
  color: #d46b08 !important;
}

.bg-orange-8 {
  background-color: #ad4e00 !important;
}

.bg-orange-8-h:hover {
  background-color: #ad4e00 !important;
}

.text-orange-8 {
  color: #ad4e00 !important;
}

.bg-orange-9 {
  background-color: #873800 !important;
}

.bg-orange-9-h:hover {
  background-color: #873800 !important;
}

.text-orange-9 {
  color: #873800 !important;
}

.bg-orange-10 {
  background-color: #612500 !important;
}

.bg-orange-10-h:hover {
  background-color: #612500 !important;
}

.text-orange-10 {
  color: #612500 !important;
}

.bg-gold-1 {
  background-color: #fffbe6 !important;
}

.bg-gold-1-h:hover {
  background-color: #fffbe6 !important;
}

.text-gold-1 {
  color: #fffbe6 !important;
}

.bg-gold-2 {
  background-color: #fff1b8 !important;
}

.bg-gold-2-h:hover {
  background-color: #fff1b8 !important;
}

.text-gold-2 {
  color: #fff1b8 !important;
}

.bg-gold-3 {
  background-color: #ffe58f !important;
}

.bg-gold-3-h:hover {
  background-color: #ffe58f !important;
}

.text-gold-3 {
  color: #ffe58f !important;
}

.bg-gold-4 {
  background-color: #ffd666 !important;
}

.bg-gold-4-h:hover {
  background-color: #ffd666 !important;
}

.text-gold-4 {
  color: #ffd666 !important;
}

.bg-gold-5 {
  background-color: #ffc53d !important;
}

.bg-gold-5-h:hover {
  background-color: #ffc53d !important;
}

.text-gold-5 {
  color: #ffc53d !important;
}

.bg-gold-6 {
  background-color: #faad14 !important;
}

.bg-gold-6-h:hover {
  background-color: #faad14 !important;
}

.text-gold-6 {
  color: #faad14 !important;
}

.bg-gold-7 {
  background-color: #d48806 !important;
}

.bg-gold-7-h:hover {
  background-color: #d48806 !important;
}

.text-gold-7 {
  color: #d48806 !important;
}

.bg-gold-8 {
  background-color: #ad6800 !important;
}

.bg-gold-8-h:hover {
  background-color: #ad6800 !important;
}

.text-gold-8 {
  color: #ad6800 !important;
}

.bg-gold-9 {
  background-color: #874d00 !important;
}

.bg-gold-9-h:hover {
  background-color: #874d00 !important;
}

.text-gold-9 {
  color: #874d00 !important;
}

.bg-gold-10 {
  background-color: #613400 !important;
}

.bg-gold-10-h:hover {
  background-color: #613400 !important;
}

.text-gold-10 {
  color: #613400 !important;
}

.bg-yellow-1 {
  background-color: #feffe6 !important;
}

.bg-yellow-1-h:hover {
  background-color: #feffe6 !important;
}

.text-yellow-1 {
  color: #feffe6 !important;
}

.bg-yellow-2 {
  background-color: #ffffb8 !important;
}

.bg-yellow-2-h:hover {
  background-color: #ffffb8 !important;
}

.text-yellow-2 {
  color: #ffffb8 !important;
}

.bg-yellow-3 {
  background-color: #fffb8f !important;
}

.bg-yellow-3-h:hover {
  background-color: #fffb8f !important;
}

.text-yellow-3 {
  color: #fffb8f !important;
}

.bg-yellow-4 {
  background-color: #fff566 !important;
}

.bg-yellow-4-h:hover {
  background-color: #fff566 !important;
}

.text-yellow-4 {
  color: #fff566 !important;
}

.bg-yellow-5 {
  background-color: #ffec3d !important;
}

.bg-yellow-5-h:hover {
  background-color: #ffec3d !important;
}

.text-yellow-5 {
  color: #ffec3d !important;
}

.bg-yellow-6 {
  background-color: #fadb14 !important;
}

.bg-yellow-6-h:hover {
  background-color: #fadb14 !important;
}

.text-yellow-6 {
  color: #fadb14 !important;
}

.bg-yellow-7 {
  background-color: #d4b106 !important;
}

.bg-yellow-7-h:hover {
  background-color: #d4b106 !important;
}

.text-yellow-7 {
  color: #d4b106 !important;
}

.bg-yellow-8 {
  background-color: #ad8b00 !important;
}

.bg-yellow-8-h:hover {
  background-color: #ad8b00 !important;
}

.text-yellow-8 {
  color: #ad8b00 !important;
}

.bg-yellow-9 {
  background-color: #876800 !important;
}

.bg-yellow-9-h:hover {
  background-color: #876800 !important;
}

.text-yellow-9 {
  color: #876800 !important;
}

.bg-yellow-10 {
  background-color: #614700 !important;
}

.bg-yellow-10-h:hover {
  background-color: #614700 !important;
}

.text-yellow-10 {
  color: #614700 !important;
}

.bg-lime-1 {
  background-color: #fcffe6 !important;
}

.bg-lime-1-h:hover {
  background-color: #fcffe6 !important;
}

.text-lime-1 {
  color: #fcffe6 !important;
}

.bg-lime-2 {
  background-color: #f4ffb8 !important;
}

.bg-lime-2-h:hover {
  background-color: #f4ffb8 !important;
}

.text-lime-2 {
  color: #f4ffb8 !important;
}

.bg-lime-3 {
  background-color: #eaff8f !important;
}

.bg-lime-3-h:hover {
  background-color: #eaff8f !important;
}

.text-lime-3 {
  color: #eaff8f !important;
}

.bg-lime-4 {
  background-color: #d3f261 !important;
}

.bg-lime-4-h:hover {
  background-color: #d3f261 !important;
}

.text-lime-4 {
  color: #d3f261 !important;
}

.bg-lime-5 {
  background-color: #bae637 !important;
}

.bg-lime-5-h:hover {
  background-color: #bae637 !important;
}

.text-lime-5 {
  color: #bae637 !important;
}

.bg-lime-6 {
  background-color: #a0d911 !important;
}

.bg-lime-6-h:hover {
  background-color: #a0d911 !important;
}

.text-lime-6 {
  color: #a0d911 !important;
}

.bg-lime-7 {
  background-color: #7cb305 !important;
}

.bg-lime-7-h:hover {
  background-color: #7cb305 !important;
}

.text-lime-7 {
  color: #7cb305 !important;
}

.bg-lime-8 {
  background-color: #5b8c00 !important;
}

.bg-lime-8-h:hover {
  background-color: #5b8c00 !important;
}

.text-lime-8 {
  color: #5b8c00 !important;
}

.bg-lime-9 {
  background-color: #3f6600 !important;
}

.bg-lime-9-h:hover {
  background-color: #3f6600 !important;
}

.text-lime-9 {
  color: #3f6600 !important;
}

.bg-lime-10 {
  background-color: #254000 !important;
}

.bg-lime-10-h:hover {
  background-color: #254000 !important;
}

.text-lime-10 {
  color: #254000 !important;
}

.bg-green-1 {
  background-color: #f6ffed !important;
}

.bg-green-1-h:hover {
  background-color: #f6ffed !important;
}

.text-green-1 {
  color: #f6ffed !important;
}

.bg-green-2 {
  background-color: #d9f7be !important;
}

.bg-green-2-h:hover {
  background-color: #d9f7be !important;
}

.text-green-2 {
  color: #d9f7be !important;
}

.bg-green-3 {
  background-color: #b7eb8f !important;
}

.bg-green-3-h:hover {
  background-color: #b7eb8f !important;
}

.text-green-3 {
  color: #b7eb8f !important;
}

.bg-green-4 {
  background-color: #95de64 !important;
}

.bg-green-4-h:hover {
  background-color: #95de64 !important;
}

.text-green-4 {
  color: #95de64 !important;
}

.bg-green-5 {
  background-color: #73d13d !important;
}

.bg-green-5-h:hover {
  background-color: #73d13d !important;
}

.text-green-5 {
  color: #73d13d !important;
}

.bg-green-6 {
  background-color: #52c41a !important;
}

.bg-green-6-h:hover {
  background-color: #52c41a !important;
}

.text-green-6 {
  color: #52c41a !important;
}

.bg-green-7 {
  background-color: #389e0d !important;
}

.bg-green-7-h:hover {
  background-color: #389e0d !important;
}

.text-green-7 {
  color: #389e0d !important;
}

.bg-green-8 {
  background-color: #237804 !important;
}

.bg-green-8-h:hover {
  background-color: #237804 !important;
}

.text-green-8 {
  color: #237804 !important;
}

.bg-green-9 {
  background-color: #135200 !important;
}

.bg-green-9-h:hover {
  background-color: #135200 !important;
}

.text-green-9 {
  color: #135200 !important;
}

.bg-green-10 {
  background-color: #092b00 !important;
}

.bg-green-10-h:hover {
  background-color: #092b00 !important;
}

.text-green-10 {
  color: #092b00 !important;
}

.bg-cyan-1 {
  background-color: #e6fffb !important;
}

.bg-cyan-1-h:hover {
  background-color: #e6fffb !important;
}

.text-cyan-1 {
  color: #e6fffb !important;
}

.bg-cyan-2 {
  background-color: #b5f5ec !important;
}

.bg-cyan-2-h:hover {
  background-color: #b5f5ec !important;
}

.text-cyan-2 {
  color: #b5f5ec !important;
}

.bg-cyan-3 {
  background-color: #87e8de !important;
}

.bg-cyan-3-h:hover {
  background-color: #87e8de !important;
}

.text-cyan-3 {
  color: #87e8de !important;
}

.bg-cyan-4 {
  background-color: #5cdbd3 !important;
}

.bg-cyan-4-h:hover {
  background-color: #5cdbd3 !important;
}

.text-cyan-4 {
  color: #5cdbd3 !important;
}

.bg-cyan-5 {
  background-color: #36cfc9 !important;
}

.bg-cyan-5-h:hover {
  background-color: #36cfc9 !important;
}

.text-cyan-5 {
  color: #36cfc9 !important;
}

.bg-cyan-6 {
  background-color: #13c2c2 !important;
}

.bg-cyan-6-h:hover {
  background-color: #13c2c2 !important;
}

.text-cyan-6 {
  color: #13c2c2 !important;
}

.bg-cyan-7 {
  background-color: #08979c !important;
}

.bg-cyan-7-h:hover {
  background-color: #08979c !important;
}

.text-cyan-7 {
  color: #08979c !important;
}

.bg-cyan-8 {
  background-color: #006d75 !important;
}

.bg-cyan-8-h:hover {
  background-color: #006d75 !important;
}

.text-cyan-8 {
  color: #006d75 !important;
}

.bg-cyan-9 {
  background-color: #00474f !important;
}

.bg-cyan-9-h:hover {
  background-color: #00474f !important;
}

.text-cyan-9 {
  color: #00474f !important;
}

.bg-cyan-10 {
  background-color: #002329 !important;
}

.bg-cyan-10-h:hover {
  background-color: #002329 !important;
}

.text-cyan-10 {
  color: #002329 !important;
}

.bg-blue-1 {
  background-color: #e6f7ff !important;
}

.bg-blue-1-h:hover {
  background-color: #e6f7ff !important;
}

.text-blue-1 {
  color: #e6f7ff !important;
}

.bg-blue-2 {
  background-color: #bae7ff !important;
}

.bg-blue-2-h:hover {
  background-color: #bae7ff !important;
}

.text-blue-2 {
  color: #bae7ff !important;
}

.bg-blue-3 {
  background-color: #91d5ff !important;
}

.bg-blue-3-h:hover {
  background-color: #91d5ff !important;
}

.text-blue-3 {
  color: #91d5ff !important;
}

.bg-blue-4 {
  background-color: #69c0ff !important;
}

.bg-blue-4-h:hover {
  background-color: #69c0ff !important;
}

.text-blue-4 {
  color: #69c0ff !important;
}

.bg-blue-5 {
  background-color: #40a9ff !important;
}

.bg-blue-5-h:hover {
  background-color: #40a9ff !important;
}

.text-blue-5 {
  color: #40a9ff !important;
}

.bg-blue-6 {
  background-color: #1890ff !important;
}

.bg-blue-6-h:hover {
  background-color: #1890ff !important;
}

.text-blue-6 {
  color: #1890ff !important;
}

.bg-blue-7 {
  background-color: #096dd9 !important;
}

.bg-blue-7-h:hover {
  background-color: #096dd9 !important;
}

.text-blue-7 {
  color: #096dd9 !important;
}

.bg-blue-8 {
  background-color: #0050b3 !important;
}

.bg-blue-8-h:hover {
  background-color: #0050b3 !important;
}

.text-blue-8 {
  color: #0050b3 !important;
}

.bg-blue-9 {
  background-color: #003a8c !important;
}

.bg-blue-9-h:hover {
  background-color: #003a8c !important;
}

.text-blue-9 {
  color: #003a8c !important;
}

.bg-blue-10 {
  background-color: #002766 !important;
}

.bg-blue-10-h:hover {
  background-color: #002766 !important;
}

.text-blue-10 {
  color: #002766 !important;
}

.bg-geekblue-1 {
  background-color: #f0f5ff !important;
}

.bg-geekblue-1-h:hover {
  background-color: #f0f5ff !important;
}

.text-geekblue-1 {
  color: #f0f5ff !important;
}

.bg-geekblue-2 {
  background-color: #d6e4ff !important;
}

.bg-geekblue-2-h:hover {
  background-color: #d6e4ff !important;
}

.text-geekblue-2 {
  color: #d6e4ff !important;
}

.bg-geekblue-3 {
  background-color: #adc6ff !important;
}

.bg-geekblue-3-h:hover {
  background-color: #adc6ff !important;
}

.text-geekblue-3 {
  color: #adc6ff !important;
}

.bg-geekblue-4 {
  background-color: #85a5ff !important;
}

.bg-geekblue-4-h:hover {
  background-color: #85a5ff !important;
}

.text-geekblue-4 {
  color: #85a5ff !important;
}

.bg-geekblue-5 {
  background-color: #597ef7 !important;
}

.bg-geekblue-5-h:hover {
  background-color: #597ef7 !important;
}

.text-geekblue-5 {
  color: #597ef7 !important;
}

.bg-geekblue-6 {
  background-color: #2f54eb !important;
}

.bg-geekblue-6-h:hover {
  background-color: #2f54eb !important;
}

.text-geekblue-6 {
  color: #2f54eb !important;
}

.bg-geekblue-7 {
  background-color: #1d39c4 !important;
}

.bg-geekblue-7-h:hover {
  background-color: #1d39c4 !important;
}

.text-geekblue-7 {
  color: #1d39c4 !important;
}

.bg-geekblue-8 {
  background-color: #10239e !important;
}

.bg-geekblue-8-h:hover {
  background-color: #10239e !important;
}

.text-geekblue-8 {
  color: #10239e !important;
}

.bg-geekblue-9 {
  background-color: #061178 !important;
}

.bg-geekblue-9-h:hover {
  background-color: #061178 !important;
}

.text-geekblue-9 {
  color: #061178 !important;
}

.bg-geekblue-10 {
  background-color: #030852 !important;
}

.bg-geekblue-10-h:hover {
  background-color: #030852 !important;
}

.text-geekblue-10 {
  color: #030852 !important;
}

.bg-purple-1 {
  background-color: #f9f0ff !important;
}

.bg-purple-1-h:hover {
  background-color: #f9f0ff !important;
}

.text-purple-1 {
  color: #f9f0ff !important;
}

.bg-purple-2 {
  background-color: #efdbff !important;
}

.bg-purple-2-h:hover {
  background-color: #efdbff !important;
}

.text-purple-2 {
  color: #efdbff !important;
}

.bg-purple-3 {
  background-color: #d3adf7 !important;
}

.bg-purple-3-h:hover {
  background-color: #d3adf7 !important;
}

.text-purple-3 {
  color: #d3adf7 !important;
}

.bg-purple-4 {
  background-color: #b37feb !important;
}

.bg-purple-4-h:hover {
  background-color: #b37feb !important;
}

.text-purple-4 {
  color: #b37feb !important;
}

.bg-purple-5 {
  background-color: #9254de !important;
}

.bg-purple-5-h:hover {
  background-color: #9254de !important;
}

.text-purple-5 {
  color: #9254de !important;
}

.bg-purple-6 {
  background-color: #722ed1 !important;
}

.bg-purple-6-h:hover {
  background-color: #722ed1 !important;
}

.text-purple-6 {
  color: #722ed1 !important;
}

.bg-purple-7 {
  background-color: #531dab !important;
}

.bg-purple-7-h:hover {
  background-color: #531dab !important;
}

.text-purple-7 {
  color: #531dab !important;
}

.bg-purple-8 {
  background-color: #391085 !important;
}

.bg-purple-8-h:hover {
  background-color: #391085 !important;
}

.text-purple-8 {
  color: #391085 !important;
}

.bg-purple-9 {
  background-color: #22075e !important;
}

.bg-purple-9-h:hover {
  background-color: #22075e !important;
}

.text-purple-9 {
  color: #22075e !important;
}

.bg-purple-10 {
  background-color: #120338 !important;
}

.bg-purple-10-h:hover {
  background-color: #120338 !important;
}

.text-purple-10 {
  color: #120338 !important;
}

.bg-magenta-1 {
  background-color: #fff0f6 !important;
}

.bg-magenta-1-h:hover {
  background-color: #fff0f6 !important;
}

.text-magenta-1 {
  color: #fff0f6 !important;
}

.bg-magenta-2 {
  background-color: #ffd6e7 !important;
}

.bg-magenta-2-h:hover {
  background-color: #ffd6e7 !important;
}

.text-magenta-2 {
  color: #ffd6e7 !important;
}

.bg-magenta-3 {
  background-color: #ffadd2 !important;
}

.bg-magenta-3-h:hover {
  background-color: #ffadd2 !important;
}

.text-magenta-3 {
  color: #ffadd2 !important;
}

.bg-magenta-4 {
  background-color: #ff85c0 !important;
}

.bg-magenta-4-h:hover {
  background-color: #ff85c0 !important;
}

.text-magenta-4 {
  color: #ff85c0 !important;
}

.bg-magenta-5 {
  background-color: #f759ab !important;
}

.bg-magenta-5-h:hover {
  background-color: #f759ab !important;
}

.text-magenta-5 {
  color: #f759ab !important;
}

.bg-magenta-6 {
  background-color: #eb2f96 !important;
}

.bg-magenta-6-h:hover {
  background-color: #eb2f96 !important;
}

.text-magenta-6 {
  color: #eb2f96 !important;
}

.bg-magenta-7 {
  background-color: #c41d7f !important;
}

.bg-magenta-7-h:hover {
  background-color: #c41d7f !important;
}

.text-magenta-7 {
  color: #c41d7f !important;
}

.bg-magenta-8 {
  background-color: #9e1068 !important;
}

.bg-magenta-8-h:hover {
  background-color: #9e1068 !important;
}

.text-magenta-8 {
  color: #9e1068 !important;
}

.bg-magenta-9 {
  background-color: #780650 !important;
}

.bg-magenta-9-h:hover {
  background-color: #780650 !important;
}

.text-magenta-9 {
  color: #780650 !important;
}

.bg-magenta-10 {
  background-color: #520339 !important;
}

.bg-magenta-10-h:hover {
  background-color: #520339 !important;
}

.text-magenta-10 {
  color: #520339 !important;
}

.bg-grey-1 {
  background-color: #ffffff !important;
}

.bg-grey-1-h:hover {
  background-color: #ffffff !important;
}

.text-grey-1 {
  color: #ffffff !important;
}

.bg-grey-2 {
  background-color: #fafafa !important;
}

.bg-grey-2-h:hover {
  background-color: #fafafa !important;
}

.text-grey-2 {
  color: #fafafa !important;
}

.bg-grey-3 {
  background-color: #f5f5f5 !important;
}

.bg-grey-3-h:hover {
  background-color: #f5f5f5 !important;
}

.text-grey-3 {
  color: #f5f5f5 !important;
}

.bg-grey-4 {
  background-color: #e8e8e8 !important;
}

.bg-grey-4-h:hover {
  background-color: #e8e8e8 !important;
}

.text-grey-4 {
  color: #e8e8e8 !important;
}

.bg-grey-5 {
  background-color: #d9d9d9 !important;
}

.bg-grey-5-h:hover {
  background-color: #d9d9d9 !important;
}

.text-grey-5 {
  color: #d9d9d9 !important;
}

.bg-grey-6 {
  background-color: #bfbfbf !important;
}

.bg-grey-6-h:hover {
  background-color: #bfbfbf !important;
}

.text-grey-6 {
  color: #bfbfbf !important;
}

.bg-grey-7 {
  background-color: #8c8c8c !important;
}

.bg-grey-7-h:hover {
  background-color: #8c8c8c !important;
}

.text-grey-7 {
  color: #8c8c8c !important;
}

.bg-grey-8 {
  background-color: #595959 !important;
}

.bg-grey-8-h:hover {
  background-color: #595959 !important;
}

.text-grey-8 {
  color: #595959 !important;
}

.bg-grey-9 {
  background-color: #262626 !important;
}

.bg-grey-9-h:hover {
  background-color: #262626 !important;
}

.text-grey-9 {
  color: #262626 !important;
}

.bg-grey-10 {
  background-color: #000000 !important;
}

.bg-grey-10-h:hover {
  background-color: #000000 !important;
}

.text-grey-10 {
  color: #000000 !important;
}

.no-data {
  color: rgba(0, 0, 0, 0.25);
}

@letterList: a, b, c, d, e, g, h, i, j, k, l, m, n, o, q, r, s, t, u, v, w, y, z;
@backList: #F32121, #F57070, #11B464, #EB8319, #E7C515, #E037F0, #33CC99, #0099FF, #00CC00, #3366FF, #CC00FF, #9999FF, #CC6633, #CC9933, #CCCC33, #FF0033, #FF9933, #FF33CC, #CC66FF, #99CCFF, #33CCFF, #33CC33, #FFCC66, #0066FF;

.back-ground(@className,@pngName) {
  .back-@{pngName} {
    background-color: @className
  }
}

.loop(@i) when (@i < length(@backList)) {
  .back-ground(extract(@backList, @i), extract(@letterList, @i));
  .loop(@i+1);
}

.loop(1);
