@import "themes/default";


.nav {
  background-color: #3184df;
  height: 48px;
  line-height: 48px;
  color: @nav-color;
  font-size: 12px;
  position: relative;
  z-index: 1;

  a {
    color: @white-color;
    text-decoration: none;
    font-size: 18px;
    display: inline-block;
    vertical-align: top;
  }

  .ant-menu.ant-menu-dark.ant-menu-horizontal .ant-menu-item-selected,
  .ant-menu.ant-menu-dark.ant-menu-horizontal .ant-menu-item-active,
  .ant-menu-submenu-popup.ant-menu-dark.ant-menu-horizontal .ant-menu-item-selected,
  .ant-menu-dark.ant-menu-horizontal .ant-menu-submenu-active,
  .ant-menu-dark.ant-menu-horizontal .ant-menu-submenu-open,
  .ant-menu-dark.ant-menu-horizontal .ant-menu-submenu-selected,
  .ant-menu-dark.ant-menu-horizontal .ant-menu-submenu-title:hover {
    background-color: transparent;
  }

  .ant-menu-dark.ant-menu-horizontal,
  .ant-menu-dark.ant-menu-horizontal .ant-menu-sub {
    background-color: transparent;
    color: @white-color;
  }

  .ant-menu-dark.ant-menu-horizontal .ant-menu-item,
  .ant-menu-dark.ant-menu-horizontal .ant-menu-item-group-title,
  .ant-menu-dark.ant-menu-horizontal .ant-menu-item > a {
    color: @white-color;
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    width: 130px;
    text-align: center;
    font-size: 18px;
    color: #fff;
  }

  .ant-menu-dark.ant-menu-horizontal > .ant-menu-item, .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
    padding: 0;
  }

  .ant-menu.index-header {
    width: 100%;
    justify-content: space-between;
  }

  .ant-menu {
    li {
      &.ant-menu-item-selected,
      &.ant-menu-submenu-selected,
      &.ant-menu-submenu-active {
        &::after {
          content: "";
          position: absolute;
          width: 32px;
          height: 4px;
          background-color: #faad14;
          //border-radius: 4px;
          bottom: -4px;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &:hover {
         & > a {
           color: #fff !important;
         }

         .ant-menu-submenu-title {
           color: #fff !important;
           border: none;
         }

         &::after {
           content: "";
           position: absolute;
           width: 32px;
           height: 4px;
           background-color: #faad14;
           //border-radius: 4px;
           bottom: -4px;
           left: 50%;
           transform: translate(-50%, -50%);
         }
       }
    }
  }

  &.phone {
    .ant-menu {
      li {
        &.ant-menu-item-selected,
        &.ant-menu-submenu-selected,
        &.ant-menu-submenu-active {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}


.ant-menu-dark.ant-menu-horizontal,
.ant-menu-dark.ant-menu-horizontal .ant-menu-sub {
  background: #3184df !important;
}

/*.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: #079aff !important;
}*/

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
  background: #3184df !important;
}

.ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover {
  color: @white-color !important;
}

.head-logo {
  // height: 45px;
  height: 32px;
  float: left;
  margin-right: 30px;

  img {
    height: 100%;
  }
}

.header {
  border-bottom: 1px solid #f0f0f0;
  background-color: @white-color;
  transition: all 0.2s;

  .ant-menu-horizontal {
    //border-bottom: none;
    line-height: 54px;
  }

  .search-box {
    transition: all 0.3s;
    width: 160px;
    height: 30px;
    border-radius: 41px;

    &.active {
      width: 250px;
    }

    i {
      color: #000000;
    }

    .anticon {
      display: none;
    }
  }

  .container {
    .ant-menu {
      flex: 1;

      .ant-menu-item {
        padding: 0;
        margin: 0 25px;
        //border: none !important;
        > a {
          color: #000000;
        }
      }

      .ant-menu-submenu {
        padding: 0 25px;
        margin: 0;
        //border: none;
        &::before {
          content: "\E680";
          position: absolute;
          right: 0;
          color: #000;
          font-family: "icon" !important;
        }

        span {
          color: #000000;
        }
      }

      .ant-menu-submenu {
        //border: none !important;
      }
    }
  }

  .ant-menu {
    li {
      float: right;

      &.ant-menu-item-selected,
      &.ant-menu-submenu-selected,
      &.ant-menu-submenu-active {
        &::after {
          content: "";
          position: absolute;
          width: 18px;
          height: 4px;
          background-color: @primary-color;
          border-radius: 4px;
          bottom: 6px;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &:hover {
        & > a {
          color: #000000 !important;
        }

        .ant-menu-submenu-title {
          color: #000000 !important;
          //border: none;
        }

        &::after {
          content: "";
          position: absolute;
          width: 18px;
          height: 4px;
          background-color: @primary-color;
          border-radius: 4px;
          bottom: 6px;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

.global-search {
  width: 250px;

  &.ant-select-auto-complete .ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 62px;
  }

  &.ant-select-auto-complete .ant-input-affix-wrapper .ant-input-suffix {
    right: 0;
  }

  &.ant-select-auto-complete .ant-input-affix-wrapper .ant-input-suffix button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .global-search-item-count {
    position: absolute;
    right: 16px;
  }
}

.ant-affix {
  .header {
    box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.05);
  }
}

.head-menu {
  position: relative;
  overflow: hidden;
  z-index: 100;
}

.gutter-example {
  .ant-menu-inline .ant-menu-item {
    font-size: 16px;
    color: #333;
    height: 64px;
    line-height: 64px;
  }

  .ant-menu-inline .ant-menu-item-selected {
    color: #fff !important;
    margin: 0 -10px;
    border-radius: 6px;
    box-shadow: 0 6px 8px 0 rgba(0, 106, 241, 0.36);
    width: calc(~"100% + 20px");
  }

  .my-menu {
    // border: none !important;
  }
}

.header-content {
  background-color: #096dd9;
  color: @gray-two-color;
  padding: 20px 0;

  .user-info {
    //float: right;
    font-size: 16px;
    line-height: 1.5;
    color: @white-color;
    margin-top: 5px;

    a {
      color: @white-color;
    }

    .info-img {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      margin-right: 12px;
      display: inline-block;
      vertical-align: middle;
    }

    em {
      margin: 0 10px;
      color: rgba(255, 255, 255, 0.6);
      font-style: normal;
    }

    .sign-out {
      color: @white-color;
      cursor: pointer;

      &:hover {
        color: @white-color;
      }
    }
  }

  .name {
    color: @gray-two-color;
    cursor: pointer;

    &:hover {
      color: @primary-color;
    }
  }

  .logo {
    img {
      height: 80px;
    }
  }
}

.header-search {
  //float: right;
  display: inline-block;
  width: 265px;
  margin: 11px 0 0 0;

  .ant-input,
  .ant-btn {
    border-radius: 0;
    height: 36px;
    line-height: 36px;
  }

  .ant-btn {
    background-color: #faad14;
    border-color: #faad14;

    &:hover {
      background-color: #de9505;
      border-color: #de9505;
    }
  }

  .ant-input {
    background-color: @white-color;
  }

  .ant-input-group .ant-input {
    text-align: left;
    line-height: 26px;
  }

  .ant-input-search .ant-input:hover, .ant-input-search .ant-input:focus {
    border-color: #faad14;
  }
}
